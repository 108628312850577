<div>
  <div class="alert alert-primary" role="alert" [ngStyle]="{'border':true? '3px solid ' + selectedWebsite.titleBarColor :''}">
    Step 3 - Product Selection
  </div>
  <div class="row">
    <div class="col-2">
      <p-selectButton [options]="vatOptions" [(ngModel)]="vatSelected" optionLabel="label" optionValue="value"
          (click)="setVat(); category=null;" [disabled]="!showPlaceOrder">
      </p-selectButton>
    </div>
    <div class="col-3" >
      <p-dropdown *ngIf="categories.length > 0" [options]="categories" placeholder="Select a category" [showClear]="true"
          [(ngModel)]="category" [style]="{'width':'100%'}" (onChange)="setCategory()" [readonly]="!showPlaceOrder">
      </p-dropdown>
    </div>
    <div class="col-3" >
      <p-dropdown *ngIf="category && (productsFiltered.length > 0)" [options]="productsFiltered" placeholder="Select a product"
          optionLabel="crmProductTitle" [(ngModel)]="selectedProduct" [style]="{'width':'100%'}" [showClear]="true" [filter]="true"
          (onChange)="onOptionsSelected()" [readonly]="!showPlaceOrder">
      </p-dropdown>
    </div>
    <div class="col-3">
      <div *ngIf="variantsFiltered.length > 0">
        <p-dropdown [options]="variantsFiltered" placeholder="Select a variant" optionLabel="crmVariantTitle"
            [(ngModel)]="selectedVariant" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder">
        </p-dropdown>
      </div>
    </div>
    <div class='col-1'>
      <button pButton type="button" label="+" class="ui-button-rounded addprod" (click)="addToInvoice()"
          [disabled]="!showPlaceOrder || !selectedProduct || ((variantsFiltered.length > 0) && !selectedVariant) ">
      </button>
    </div>
    <div *ngIf="(vatSelected === false) && (orderVatStatus === OrderVatStatusEnum.VATABLE)" class='col-12'>
      <div class="alert alert-danger">
        You have "Not Exempt" items, that are not key safes, in the basket. 
        You cannot mix "Not Exempt" items, other than keysafes, with and "Exempt" items,
        please remove the non keysafe items to add "Exempt" items.
      </div>
    </div>
  </div>

  <div class="row">
    <table class="table">
      <tr>
        <th>Order</th>
        <th>Quantity</th>
        <td></td>
        <th>Total</th>
        <th></th>
      </tr>
      <tbody *ngFor="let shopifyItem of shopifyItems; let i = index">
        <tr>
          <td> {{shopifyItem.shopifyVariant.crmVariantTitle}} : {{shopifyItem.shopifyVariant.sku}} </td>
          <td>
            <input type="number" min='1' (keypress)="numberOnly($event, false)" [(ngModel)]="shopifyItem.quantity"
              (change)="priceupdate()" [readonly]="!showPlaceOrder">
          </td>
          <td></td>
          <td>
            <div *ngIf="(shopifyItem.quantity > 0) && (shopifyItem.regularPrice > 0)">
              {{shopifyItem.quantity * (shopifyItem.subtotalExVat + shopifyItem.subtotalVat) | currency :shopifyOrder.currency:'symbol':'1.2-2' }}
            </div>
          </td>
          <td>
            <ng-container *ngIf="!shopifyItem.shopifyVariant.sku.includes('SETUP')">
              <button class="btn btn-danger btn-sm rounded-0" type="button" data-toggle="tooltip" data-placement="top"
                  title="Delete" (click)="delete(i)" [disabled]="!showPlaceOrder">
                <i class="fa fa-trash"></i>
              </button>
            </ng-container>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="calculationError">
        <tr>
          <th colspan="5">
            <p class="alert alert-danger">{{calculationError}}</p>
          </th>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th>VAT</th>
          <th colspan='2'></th>
          <th>{{vatTotal | currency :shopifyOrder.currency:'symbol':'1.2-2'}}</th>
          <th></th>
        </tr>
        <ng-container *ngIf="!isExistingCustomer()">
          <tr>
            <th colspan="5">Discount code</th>
          </tr>
          <tr>
            <th>
              <input type="text" placeholder="Please enter Discount code" (change)="searchDiscount()" [(ngModel)]='discountCode' [readonly]="!showPlaceOrder">
            </th>
            <th>
              <p *ngIf="discount">{{discount.shortSummary}}</p>
              <p *ngIf="discountCodeError" class="alert alert-danger">{{discountCodeError}}</p>
            </th>
            <th></th>
            <th>
              <ng-container *ngIf="discount && !discountCodeError">
                <ng-container *ngIf="(discount.valueType === 'percent')">
                  - {{discount.discount * 100}}% 
                </ng-container>
                <ng-container *ngIf="(discount.valueType === 'amount')">
                  - {{currencySymbol}}{{discount.discount}} 
                </ng-container>
              </ng-container>
            </th>
            <th></th>
          </tr>
        </ng-container>
        <ng-container *ngIf="(baseOrderInformation.cseOrder.proRataCurrentPrice > 0) && selectedOrderType && (selectedOrderType.calculationMethod === 'Difference')">
          <th>Pro-Rata existing Plan Price (subtracted from new price)</th>
          <th colspan='2'></th>
          <th>-{{baseOrderInformation.cseOrder.proRataCurrentPrice | currency :shopifyOrder.currency:'symbol':'1.2-2'}}</th>
          <th></th>
        </ng-container>
        <tr>
          <th>Order Total</th>
          <th colspan='2'></th>
          <th>{{orderTotal | currency :shopifyOrder.currency:'symbol':'1.2-2'}}</th>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div>
  <div class="alert alert-primary" role="alert" [ngStyle]="{'border':true? '3px solid ' + selectedWebsite.titleBarColor :''}">
    Step 4 - Customer Details
  </div>
  <div class="row">
    <h3 style="padding-left: 14px;">Billing Details</h3>
  </div>
  <div class="row">
    <div class="col-3"><label>First Name</label></div>
    <div class="col-3">
      <input [ngClass]="{'error' : validationErrorExists && customerFirstName.trim()==='' }" type="text"
          [(ngModel)]="customerFirstName" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder" autocomplete="off">
    </div>

    <div class="col-3"><label>Last Name</label></div>
    <div class="col-3">
      <input [ngClass]="{'error' : validationErrorExists && customerLastName.trim()==='' }" type="text"
          [(ngModel)]="customerLastName" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder" autocomplete="off">
    </div>

    <div class="col-3">
      <label>Email</label><br/>
      Leave blank if customer does not have one
    </div>
    <div class="col-3">
      <input type="text" [(ngModel)]="shopifyCustomer.email" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder" (blur)="emailBlur()">
    </div>

    <div class="col-3"><label>Phone</label></div>
    <div class="col-3">
      <input [ngClass]="{'error' : validationErrorExists && customerPhone.trim()==='' }" type="text"
          [(ngModel)]="customerPhone" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder" maxlength="20"
          autocomplete="off" (keypress)="numberOnly($event, false)">
    </div>
    <div class="col-3"><label>Address</label></div>
    <div class="form-group col-9" id="postcode_lookup_billing">
      <input type="text" id="getaddress_input_billing" autocomplete="off" [(ngModel)]="billingSearchPostCode">
      <button type="button" id="getaddress_button_billing" (click)="billingAddressSearch()" [disabled]="!billingSearchPostCode">
        Find Postcode
      </button>
    </div>
    <div *ngIf="billingSearchError" class="form-group col-9 offset-3">
      <p class="alert alert-danger">{{billingSearchError}}</p>
    </div>
    <div *ngIf="billingAddressResults && (billingAddressResults.length > 0)" class="form-group col-9 offset-3">
      <p-dropdown [options]="billingAddressResults" [style]="{'width':'100%'}" (onChange)="setBillingAddress($event)"
          [readonly]="!showPlaceOrder">
      </p-dropdown>
    </div>
    <div class="col-9 offset-3">
      <input [ngClass]="{'error' : validationErrorExists && shopifyOrder.billing_address.address1==='' }"
          type="text" placeholder="Address Line 1" [(ngModel)]="shopifyOrder.billing_address.address1" 
          [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowBillingAddressManualEntry"
          autocomplete="off">
    </div>
    <div class="col-9 offset-3">
      <input type="text" placeholder="Address Line 2" [(ngModel)]="shopifyOrder.billing_address.address2"
          [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowBillingAddressManualEntry" autocomplete="off">
    </div>
    <div class="col-9 offset-3">
      <input [ngClass]="{'error' : validationErrorExists && shopifyOrder.billing_address.city.trim()==='' }"
          type="text" placeholder="Town / City" [(ngModel)]="shopifyOrder.billing_address.city" 
          [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowBillingAddressManualEntry" autocomplete="off">
    </div>
    <div class="col-9 offset-3">
      <input type="text" placeholder="County" [(ngModel)]="shopifyOrder.billing_address.province"
          [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowBillingAddressManualEntry" autocomplete="off">
    </div>
    <div class="col-3"><label>Postcode</label></div>
    <div class="col-9">
      <input [ngClass]="{'error' : validationErrorExists && shopifyOrder.billing_address.zip.trim()==='' }" type="text"
          [(ngModel)]="shopifyOrder.billing_address.zip" [style]="{'width':'100%'}"
          [readonly]="!showPlaceOrder || !allowBillingAddressManualEntry" autocomplete="off">
    </div>
  </div>
  <div class="row">
    <div class="col-3"><label>Deliver to a different Address?</label></div>
    <div class="col-1">
      <input type="checkbox" [(ngModel)]="differentDeliver" style="width: auto;" [disabled]="!showPlaceOrder">
    </div>
  </div>
  <div class="row" *ngIf="differentDeliver">
    <div class="col-3"><label>First Name</label></div>
    <div class="col-3">
      <input [ngClass]="{'error' : validationErrorExists && shopifyOrder.shipping_address.first_name.trim()===''}"
          type="text" [(ngModel)]="shopifyOrder.shipping_address.first_name" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder"
          autocomplete="off">
    </div>

    <div class="col-3"><label>Last Name</label></div>
    <div class="col-3">
      <input [ngClass]="{'error' : validationErrorExists && shopifyOrder.shipping_address.last_name.trim()===''}"
          type="text" [(ngModel)]="shopifyOrder.shipping_address.last_name" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder"
          autocomplete="off">
    </div>

    <div class="col-3"><label>Address</label></div>
    <div class="form-group col-9" id="delivery_postcode_lookup">
      <input type="text" id="getaddress_input_delivery" autocomplete="off" [(ngModel)]="deliverySearchPostCode">
      <button type="button" id="getaddress_button_delivery" (click)="deliveryAddressSearch()" [disabled]="!deliverySearchPostCode">
        Find Postcode
      </button>
    </div>
    <div *ngIf="deliverySearchError" class="form-group col-9 offset-3">
      <p class="alert alert-danger">{{deliverySearchError}}</p>
    </div>
    <div *ngIf="deliveryAddressResults && (deliveryAddressResults.length > 0)" class="form-group col-9 offset-3">
      <p-dropdown [options]="deliveryAddressResults" [style]="{'width':'100%'}" (onChange)="setDeliveryAddress($event)"
          [readonly]="!showPlaceOrder">
      </p-dropdown>
    </div>
    <div class="col-9 offset-3">
      <input [ngClass]="{'error' : validationErrorExists && shopifyOrder.shipping_address.address1.trim()===''}"
          type="text" placeholder="Address Line 1" [(ngModel)]="shopifyOrder.shipping_address.address1" 
          [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowDeliveryAddressManualEntry" autocomplete="off">   
    </div>
    <div class="col-9 offset-3">
      <input type="text" placeholder="Address Line 2" [(ngModel)]="shopifyOrder.shipping_address.address2"
          [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowDeliveryAddressManualEntry" autocomplete="off">
    </div>
    <div class="col-9 offset-3">
      <input [ngClass]="{'error' : validationErrorExists && shopifyOrder.shipping_address.city.trim()===''}"
          type="text" placeholder="Town / City" [(ngModel)]="shopifyOrder.shipping_address.city" 
          [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowDeliveryAddressManualEntry" autocomplete="off">
    </div>
    <div class="col-9 offset-3">
      <input type="text" placeholder="County" [(ngModel)]="shopifyOrder.shipping_address.province" 
          [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowDeliveryAddressManualEntry" autocomplete="off">
    </div>
    <div class="col-3"><label>PostCode</label></div>
    <div class="col-9">
      <input [ngClass]="{'error' : validationErrorExists && shopifyOrder.shipping_address.zip.trim()===''}"
          type="text" [(ngModel)]="shopifyOrder.shipping_address.zip" [style]="{'width':'100%'}" autocomplete="off"
          [readonly]="!showPlaceOrder || !allowDeliveryAddressManualEntry">
    </div>
  </div>
  <br>
</div>

<div>
  <div class="alert alert-primary" role="alert" [ngStyle]="{'border':true? '3px solid ' + selectedWebsite.titleBarColor :''}">
    Step 5 - Order Details
  </div>
  <div class="row Order">
    <div class="col-3"><label>Order Notes</label></div>
    <div class="col-9">
      <input [(ngModel)]="shopifyOrder.note" type="text" [readonly]="!showPlaceOrder" autocomplete="off">
    </div>
    <ng-container *ngIf="!isExistingCustomer()">
      <div class="col-3"><label>Alarm User's Name</label></div>
      <div class="col-9">
        <select [ngClass]="{'error' : validationErrorExists && !alarmUserNameOption}"
            [style]="{'width':'100%'}" [(ngModel)]="alarmUserNameOption" [disabled]="!showPlaceOrder">
          <option value=""></option>
          <option value="billing">Same as Billing Address Name</option>
          <option *ngIf="differentDeliver" value="delivery">Same as Delivery Address Name</option>
          <option value="other">Other</option>
        </select>
      </div>
      <ng-container *ngIf="alarmUserNameOption === 'other'">
        <div class="col-3"><label>Alarm User's First Name</label></div>
        <div class="col-3">
          <input [ngClass]="{'error' : validationErrorExists && !orderMetadata.alarmUserFirstName}" type="text" 
              [(ngModel)]="orderMetadata.alarmUserFirstName" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder"
              autocomplete="off">
        </div>
        <div class="col-3"><label>Alarm User's Last Name</label></div>
        <div class="col-3">
          <input [ngClass]="{'error' : validationErrorExists && !orderMetadata.alarmUserLastName}" type="text" 
              [(ngModel)]="orderMetadata.alarmUserLastName" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder"
              autocomplete="off">
        </div>
      </ng-container>
      <div class="col-3"><label>Alarm User's Phone</label></div>
      <div class="col-9">
        <select [ngClass]="{'error' : validationErrorExists && !alarmUserPhoneOption}"
            [style]="{'width':'100%'}" [(ngModel)]="alarmUserPhoneOption" [disabled]="!showPlaceOrder">
          <option value=""></option>
          <option value="billing">Same as Billing Phone</option>
          <option value="other">Other</option>
          <option value="unknown">Unknown</option>
        </select>
      </div>
      <ng-container *ngIf="alarmUserPhoneOption === 'other'">
        <div class="col-3"><label>Alarm User's Phone</label></div>
        <div class="col-9">
          <input [ngClass]="{'error' : validationErrorExists && (!orderMetadata.alarmUserMobile && !orderMetadata.alarmUserPhone)}" type="text" 
              [(ngModel)]="alarmUserPhone" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder" (keypress)="numberOnly($event, false)"
              autocomplete="off">
        </div>
        <div class="col-3"><label>Alarm User's Mobile</label></div>
        <div class="col-9">
          <input [ngClass]="{'error' : validationErrorExists && (!orderMetadata.alarmUserMobile && !orderMetadata.alarmUserPhone)}" type="text" 
              [(ngModel)]="alarmUserMobile" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder" (keypress)="numberOnly($event, false)"
              autocomplete="off">
        </div>
      </ng-container>
      <div class="col-3"><label>Alarm User's Address</label></div>
      <div class="col-9">
        <select [ngClass]="{'error' : validationErrorExists && !alarmUserAddressOption}"
            [style]="{'width':'100%'}" [(ngModel)]="alarmUserAddressOption" [disabled]="!showPlaceOrder">
          <option value=""></option>
          <option value="billing">Same as Billing Address</option>
          <option *ngIf="differentDeliver" value="delivery">Same as Delivery Address</option>
          <option value="other">Other</option>
          <option value="unknown">Unknown</option>
        </select>
      </div>
      <ng-container *ngIf="alarmUserAddressOption === 'other'">
        <div class="col-3"><label>Address</label></div>
        <div class="form-group col-9" id="postcode_lookup_alarm_user">
          <input type="text" id="getaddress_input_alarm_user" autocomplete="off" [(ngModel)]="alarmUserSearchPostCode">
          <button type="button" id="getaddress_button_alarm_user" (click)="alarmUserAddressSearch()" [disabled]="!alarmUserSearchPostCode">
            Find Postcode
          </button>
        </div>
        <div *ngIf="alarmUserSearchError" class="form-group col-9 offset-3">
          <p class="alert alert-danger">{{alarmUserSearchError}}</p>
        </div>
        <div *ngIf="alarmUserAddressResults && (alarmUserAddressResults.length > 0)" class="form-group col-9 offset-3">
          <p-dropdown [options]="alarmUserAddressResults" [style]="{'width':'100%'}" (onChange)="setAlarmUserAddress($event)"
              [readonly]="!showPlaceOrder">
          </p-dropdown>
        </div>
        <div class="col-9 offset-3">
          <input [ngClass]="{'error' : validationErrorExists && alarmUserAddress.addressOne.trim()===''}"
              type="text" placeholder="Address Line 1" [(ngModel)]="alarmUserAddress.addressOne" 
              [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowAlarmUserAddressManualEntry" autocomplete="off">
        </div>
        <div class="col-9 offset-3">
          <input type="text" placeholder="Address Line 2" [(ngModel)]="alarmUserAddress.addressTwo"
              [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowAlarmUserAddressManualEntry" autocomplete="off">
        </div>
        <div class="col-9 offset-3">
          <input [ngClass]="{'error' : validationErrorExists && alarmUserAddress.city.trim()===''}"
              type="text" placeholder="Town / City" [(ngModel)]="alarmUserAddress.city" 
              [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowAlarmUserAddressManualEntry" autocomplete="off">
        </div>
        <div class="col-9 offset-3">
          <input type="text" placeholder="County" [(ngModel)]="alarmUserAddress.county" 
              [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowAlarmUserAddressManualEntry" autocomplete="off">
        </div>
        <div class="col-3"><label>PostCode</label></div>
        <div class="col-9">
          <input [ngClass]="{'error' : validationErrorExists && alarmUserAddress.postcode.trim()===''}"
              type="text" [(ngModel)]="alarmUserAddress.postcode" [style]="{'width':'100%'}" autocomplete="off"
              [readonly]="!showPlaceOrder || !allowAlarmUserAddressManualEntry">
        </div>
      </ng-container>
      <div class="col-3">
        <label>Renewal Method</label>
      </div>
      <div class="col-9">
        <select [ngClass]="{'error' : validationErrorExists && !orderMetadata.renewalMethod}"
            [(ngModel)]="orderMetadata.renewalMethod" (change)="renewalMethodChange()" [disabled]="!showPlaceOrder">
          <option value=""></option>
          <option value="None">None</option>
          <option *ngIf="selectedWebsite.title !== 'LLIE'" value="directDebit">Direct Debit</option>
          <option *ngIf="selectedWebsite.title === 'LLIE'" value="goCardless">Go Cardless</option>
          <option value="recurringBilling">Recurring Billing</option>
        </select>
      </div>
      <div class="col-3"><label>Paper NCF Required?</label></div>
      <div class="col-9">
        <select [ngClass]="{'error' : validationErrorExists && !orderMetadata.ncfRequired}"
            [(ngModel)]="orderMetadata.ncfRequired" [disabled]="!showPlaceOrder">
          <option value="">Please Select</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
    </ng-container>
  </div>
</div>

<div >
  <div class="alert alert-primary" role="alert" [ngStyle]="{'border':true? '3px solid ' + selectedWebsite.titleBarColor :''}">
    Step 6 - Basket
  </div>
  <div class="row">
    <table class="table">
      <tr>
        <th>Order</th>
        <th>Quantity</th>
        <th></th>
        <th>Total</th>
      </tr>
      <tbody *ngFor="let shopifyItem of shopifyItems; let i = index">
        <tr>
          <td>{{shopifyItem.shopifyVariant.crmVariantTitle}} : {{shopifyItem.shopifyVariant.sku}}</td>
          <td>
            <input type="number" min='1' (keypress)="numberOnly($event, false)" [(ngModel)]="shopifyItem.quantity"
                (change)="priceupdate()" [readonly]="!showPlaceOrder">
          </td>
          <td>
            <div *ngIf="overrideTotal">
              <input type="number" step="0.01" (keypress)="numberOnly($event, true)" [(ngModel)]="shopifyItem.regularPrice" (change)="priceupdate()" [readonly]="!showPlaceOrder">
            </div>
          </td>
          <td>
            {{shopifyItem.quantity * (shopifyItem.subtotalExVat + shopifyItem.subtotalVat) | currency :shopifyOrder.currency:'symbol':'1.2-2' }}
          </td>
        </tr>
      </tbody>
      <tr *ngIf="!isExistingCustomer() && discount">
        <th>
          Discount
        </th>
        <th>
          <p>{{discount.shortSummary}}</p>
          <p *ngIf="discountCodeError" class="alert alert-danger">{{discountCodeError}}</p>
        </th>
        <th></th>
        <th>
          <ng-container *ngIf="discount && !discountCodeError">
            <ng-container *ngIf="(discount.valueType === 'percent')">
              - {{discount.discount * 100}}% 
            </ng-container>
            <ng-container *ngIf="(discount.valueType === 'amount')">
              - {{currencySymbol}}{{discount.discount}} 
            </ng-container>
          </ng-container>
        </th>
      </tr>
      <ng-container *ngIf="(baseOrderInformation.cseOrder.proRataCurrentPrice > 0) && selectedOrderType && (selectedOrderType.calculationMethod === 'Difference')">
        <th>Pro-Rata existing Plan Price (subtracted from new price)</th>
        <th colspan='2'></th>
        <th>-{{baseOrderInformation.cseOrder.proRataCurrentPrice | currency :shopifyOrder.currency:'symbol':'1.2-2'}}</th>
        <th></th>
      </ng-container>
      <tr>
        <th>Order Total</th>
        <th></th>
        <th></th>
        <th>{{orderTotal | currency :shopifyOrder.currency:'symbol':'1.2-2'}}</th>
      </tr>
      <tr>
        <th>
          Override Total?
          <div class="totalOverride"><input type="checkbox" [(ngModel)]="overrideTotal" [disabled]="!showPlaceOrder"></div>
          <span *ngIf="overrideTotal">Why are you overriding the total?</span>
        </th>
        <th>
          <select *ngIf="overrideTotal" [(ngModel)]="baseOrderInformation.cseOrder.overrideReason" [disabled]="!showPlaceOrder">
            <option value="">Please Select</option>
            <option value="Complaint">Complaint</option>
            <option value="CSE given Discount">CSE given Discount</option>
            <option value="Downgrade">Downgrade</option>
            <option value="Honouring Price">Honouring Price</option>
            <option value="Price Frozen">Price Frozen</option>
            <option value="Partner Discount">Partner Discount</option>
            <option value="Switched Plans">Switched Plans</option>
            <option value="TH Digital">TH Digital</option>
            <option value="Upgrade">Upgrade</option>
            <option value="Wrong Price Recorded">Wrong Price Recorded</option>
            <option value="Other">Other</option>
          </select>
          <div class="alert alert-danger" *ngIf="overrideTotal && !baseOrderInformation.cseOrder.overrideReason">You must select a reason for the override.</div>
        </th>
        <th colspan="2">
          <div *ngIf="overrideTotal && baseOrderInformation.cseOrder.overrideReason === 'Other'">
            <input [(ngModel)]="baseOrderInformation.cseOrder.overrideReasonOther" type="text" [readonly]="!showPlaceOrder"
              autocomplete="off" name="otherOverrideReason" style="width: 100%;" >
          </div>
        </th> 
      </tr>
    </table>
  </div>

</div>
<div>
  <div class="alert alert-primary" role="alert" [ngStyle]="{'border':true? '3px solid ' + selectedWebsite.titleBarColor :''}">
    Step 7 - Checkout
  </div>
  <div class="row Checkout">
    <div class="col-3">
      <label>Payment Method</label>
    </div>
    <div *ngIf="orderTotal === 0" class="col-9" style="margin-bottom: 20px;">
      <input type="text" readonly [(ngModel)]="orderMetadata.paymentMethod"/>
    </div>
    <div *ngIf="orderTotal > 0" class="col-9" style="margin-bottom: 20px;">
      <select [ngClass]="{'error' : validationErrorExists && !orderMetadata.paymentMethod}"
          [(ngModel)]="orderMetadata.paymentMethod" [disabled]="!showPlaceOrder">
        <option value="">Please Select</option>
        <option value="Secured Debit/Credit Card">Secured Debit/Credit Card</option>
        <option value="Cheque Payment">Cheque Payment</option>
        <option *ngIf="selectedWebsite.title !== 'LLIE'" value="Direct Debit">Direct Debit</option>
        <option value="Referral – Customer will pay later">Referral – Customer will pay later</option>
        <option value="Manual Stripe Payment">Manual Stripe Payment</option>
      </select>
    </div>
  </div>
  <ng-container *ngIf="!isExistingCustomer()">
    <div class="row checkout">
      <div class="col-3">
        <label>How did you hear about us?</label>
      </div>
      <div class="col-3">
        <p-dropdown [options]="howHeardOptions" placeholder="How the customer heard about us"
            [(ngModel)]="baseOrderInformation.cseOrder.howHeard" [style]="{'width':'100%'}" [showClear]="true" [filter]="true"
            [readonly]="!showPlaceOrder" (onChange)="changeHowHeard()">
        </p-dropdown>
      </div>
      <ng-container *ngIf="(baseOrderInformation.cseOrder.howHeard === 'Partnership') && (partnerships.length > 0)">
        <div class="col-2">
          <label>Which Partnership?</label>
        </div>
        <div class="col-4">
          <p-dropdown [options]="partnerships" placeholder="Select which partnership"
              [(ngModel)]="selectedPartnership" [style]="{'width':'100%'}" [showClear]="true" [filter]="true"
              [readonly]="!showPlaceOrder" (onChange)="changePartnership()">
          </p-dropdown>
        </div>
      </ng-container>
      <ng-container *ngIf="(baseOrderInformation.cseOrder.howHeard === 'M&S Referral Friends/Family')">
        <div class="col-2">
          <label>Enter TD Code</label>
        </div>
        <div class="col-4">
          <input [ngClass]="{'error': !referralTdFound}" type="text" placeholder="Search CRM - TD Code"
                 [(ngModel)]="referralTDCode" (change)="searchReferral()" [readonly]="!showPlaceOrder">
          <p *ngIf="referralTdSearched && !referralTdFound" class="alert alert-danger">TD Code Not Found</p>
        </div>
      </ng-container>
    </div>
    <div class="row marketing">
      <div class="col-12 mb-2">
        Please remember to offer a reviews feedback invite.<br/>
        Please remember to offer a referral to friends or family if they are happy with our service.
      </div>
      <div class="col-12">
        As part of our life saving service we will occasionally send you updates, recomendations & offers via email and phone/SMS,
        can you please confirm if are you happy to receive these communications?
      </div>
      <div class="col-3">
        By Email:
      </div>
      <div class="col-9">
        <p-dropdown [options]="emailOptInSelects" [(ngModel)]="shopifyCustomer.emailMarketingConsent.marketingState">
        </p-dropdown>
      </div>
      <div class="col-3">
        By Phone:
      </div>
      <div class="col-9">
        <p-dropdown [options]="smsOptInSelects" [(ngModel)]="shopifyCustomer.smsMarketingConsent.marketingState">
        </p-dropdown>
      </div>
    </div>
  </ng-container>
  <div *ngIf="showProgressBar">
    <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
    <br>
    <br>
    <br>
  </div>
  <div *ngIf="validationErrors.length > 0" class="alert alert-danger" role="alert">
    Validation Errors:
    <ul>
      <li *ngFor="let error of validationErrors" >{{error}}</li>
    </ul>
  </div>
  <div *ngIf="!showPlaceOrder && !!currentStep" class="alert alert-success" role="alert">
    Completed Steps:
    <ul>
      <ng-container *ngFor="let stepName of Object.keys(processingSteps)">
        <li *ngIf="processingSteps[stepName].required && processingSteps[stepName].completed">
          {{stepName}}
        </li>
      </ng-container>
    </ul>
  </div>
  <div *ngIf="!!currentStep" class="alert alert-info" role="alert">
    Currently Processing Step: {{currentStep}}
  </div>
  <div *ngIf="!!processingError" class="alert alert-danger" role="alert">
    <div class="processingMsg">
      Error on Step: {{currentStep}}
      <br/>
      <span class="font-weight-bold">Error:</span>{{processingError.message}}
      <ng-container *ngIf="!!processingError.code">
        <span class="font-weight-bold">Error code:</span>{{processingError.code}}
      </ng-container>
      <button (click)="displayErrorDetails = !displayErrorDetails" type="button" class="btn minMax">
        <i *ngIf="displayErrorDetails" class="pi pi-minus"></i>
        <i *ngIf="!displayErrorDetails" class="pi pi-plus"></i>
      </button>
    </div>
    <div [ngStyle]="{'display': displayErrorDetails? 'block': 'none'}">
      <div>
        <ng-container *ngIf="processingSteps[currentStep].allowToBeSkipped">
          {{processingSteps[currentStep].messageToDisplayIfCanSkip}}
        </ng-container>
        <ng-container *ngIf="!processingSteps[currentStep].allowToBeSkipped">
          {{processingSteps[currentStep].messageToDisplay}}
        </ng-container>
      </div>
      <ng-container [ngSwitch]="currentStep">
        <ng-container *ngSwitchCase="['Creating Payment Intent on Stripe', 'Checking User Supplied Payment Reference'].includes(currentStep)? currentStep: ''">
          <ng-container *ngIf="processingSteps[currentStep].allowToBeSkipped">
            Payment Reference (this is the reference for the payment on Stripe that starts pi_):
            <input type="text" [(ngModel)]="processingSteps['Creating Payment Intent on Stripe'].resultData" class="paymentRef" class="retryId" />
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Creating Direct Debit Form'">
          <input type="checkbox" (change)="manuallyCompleteStep($event)" />
        </ng-container>
        <ng-container *ngSwitchCase="[ORDER_CREATION_STEP, 'Checking User Supplied OrderId'].includes(currentStep)? currentStep: ''">
          Order Id (this is the number beside the # in the site's admin area. Do not include the #):
          <input type="string" [(ngModel)]="draftOrderId" class="retryId" (keypress)="numberOnly($event, false)" />
        </ng-container>
        <ng-container *ngSwitchCase="'Importing into CRM'">
          <input type="checkbox" (change)="manuallyCompleteStep($event)"/>
        </ng-container>
      </ng-container>
      <div>
        Clicking RETRY will continue from the first uncompleted step.<br/>
        Steps still to do:
        <ul>
          <ng-container *ngFor="let stepName of Object.keys(processingSteps)">
            <li *ngIf="processingSteps[stepName].required && !processingSteps[stepName].completed">
              {{stepName}}
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <div>
      <button pButton type="button" label="RETRY" class="ui-button-rounded" (click)="retryOrder()">
      </button>
    </div>
  </div>
  <div *ngIf="!showProgressBar && processingSteps[ORDER_CREATION_STEP].completed">
    <div class="alert alert-success" role="alert">
      Order Received <br>
      <a *ngIf="!!orderLink" href="{{orderLink}}" target="_blank" class="alert-link">View this order in the admin area </a> 
      <ng-container *ngIf="orderCreationSuccess">
        <ng-container *ngIf="!!crmOrderLink">
          | <a routerLink="{{crmOrderLink}}" class="alert-link">View order on CRM</a>
        </ng-container>
        | <a (click)="refresh()" class="alert-link">Add new manual order</a>
      </ng-container>
    </div>
  </div>
</div>

<div class="row" *ngIf="orderMetadata.paymentMethod !== 'Secured Debit/Credit Card'">
  <div class="col-3 offset-9" *ngIf="showPlaceOrder">
    <button [disabled]="isPlaceOrderDisabled" pButton type="button" label="PLACE ORDER"
        class="ui-button-rounded" (click)="createOrder()">
    </button>
  </div>
</div>

<div class="container-fluid" [ngStyle]="{'display': orderMetadata.paymentMethod === 'Secured Debit/Credit Card'? 'initial' : 'none'}">
  <div class="form-row">
    <div class="col-3">
      <label>Name on Card</label>
    </div>
    <div class="col-9">
      <input type="text" [(ngModel)]="orderMetadata.nameOnCard" [readonly]="!showPlaceOrder" autocomplete="off"/>
    </div>
  </div>
  <form (ngSubmit)="createCardPaidOrder()" class="checkout" >
    <div class="form-row">
      <div id="card-info" #cardInfo></div>
      <div id="card-errors" role="alert" *ngIf="stripeCardError"><p>{{stripeCardError}}</p></div>
    </div>
    <button type="submit" *ngIf="showPlaceOrder" [disabled]="isPlaceOrderDisabled">
      PLACE ORDER
    </button>
  </form>
</div>