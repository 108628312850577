import {SetupFeeConfig} from '../../../models/cseOrder/setupFeeConfig.model';

const SHOPIFY_SETUP_FEE_CONFIGS: {[websiteId: string]: SetupFeeConfig} = {
  '5ce1e1043c52bd1e7fe45a77': {
    'normalFeeProducts': ['LL-SETUP-FEE-INC', 'LL-SETUP-FEE-EX'],
    'reducedFeeProducts': ['SETUP-FEE-VAT-INC-ANALOGUE', 'SETUP-FEE-VAT-EX-ANALOGUE'],
    // TODO take these out when Trojan horse needs deactivating
    'productsRequiringReducedFee': ['LL-TUN-57000/320-Q-EX', 'LL-TUN-57000/320-Q-INC'],
    // 'productsRequiringReducedFee': [],
  },
  '5cd454426c0e9821baca0c1d': {
    'normalFeeProducts': ['SETUP-FEE-VAT-INC', 'SETUP-FEE-VAT-EX'],
    'reducedFeeProducts': ['SETUP-FEE-VAT-INC-ANALOGUE', 'SETUP-FEE-VAT-EX-ANALOGUE'],
    // TODO take these out when Trojan horse needs deactivating
    'productsRequiringReducedFee': ['CL-CMP-VAT-INC', 'CL-CMP-VAT-EXC', 'CL-FD-M-VAT-EXC', 'CL-FD-M-VAT-INC'],
    // 'productsRequiringReducedFee': [],
  },
};

export {
  SHOPIFY_SETUP_FEE_CONFIGS,
};
