import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule} from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from './data.service';
import {AuthGuardService} from './auth-guard.service';
import {TabViewModule} from 'primeng/tabview';
import {MessageComponent} from './message/message.component';
import {RegistrationComponent} from './registration/registration.component';
import {LoginComponent} from './login/login.component';
import {ProfileComponent} from './profile/profile.component';
import {SettingsComponent} from './settings/settings.component';
import {WebsitesComponent} from './setup/websites/websites.component';
import {PostOrderComponent} from './post-order/post-order.component';
import {MyOrdersComponent} from './my-orders/my-orders.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ProductsComponent} from './setup/products/products.component';
import {TagsComponent} from './setup/tags/tags.component';
import {TableModule} from "primeng/table";
import {PaginatorModule} from 'primeng/paginator';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {InputTextModule} from 'primeng/inputtext';
import {MultiSelectModule} from 'primeng/multiselect';
import {ListboxModule} from 'primeng/listbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FilterPipe} from './post-order/filter.pipe';
import {FilterlogPipe} from './post-order/filterlog.pipe';
import {BlockUIModule} from 'primeng/blockui';
import {PanelModule} from 'primeng/panel';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';
import {SidebarModule} from 'primeng/sidebar';
import {DataViewModule} from 'primeng/dataview';
import {AccordionModule} from 'primeng/accordion';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ProgressBarModule} from 'primeng/progressbar';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {HighlightPipe} from './post-order/highlight.pipe';
import {UsersComponent} from './setup/users/users.component';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {PermissionComponent} from './permission/permission.component';
import {CardModule} from 'primeng/card';
import {ChartModule} from 'primeng/chart';
import {FeedbackComponent} from './feedback/feedback.component';
import {AuthenticationService} from './authentication.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {LogsComponent} from './reporting/logs/logs.component';
import {ImportLogComponent} from './reporting/import-log/import-log.component';
import {CseOrderComponent} from './cse-order/cse-order.component';
import {StepsModule} from 'primeng/steps';
import {HomeOutboundComponent} from './home-outbound/home-outbound.component';
import {OutstandingActionComponent} from './home-outbound/outstanding-action/outstanding-action.component';
import {OutstandingActionsListComponent} from './home-outbound/outstanding-actions-list/outstanding-actions-list.component';
import {ContactAttemptComponent} from './post-order/contact-attempt/contact-attempt.component';
import {RenewalUpdateComponent} from './post-order/renewal-update/renewal-update.component';
import {FrozenPriceComponent} from './post-order/frozen-price/frozen-price.component';
import {SelectButtonModule} from 'primeng/selectbutton';
import {MessagesListComponent} from './messages-list/messages-list.component';
import {MessageDetailComponent} from './messages-list/message-detail/message-detail.component';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {CheckboxModule} from 'primeng/checkbox';
import {TreeTableModule} from 'primeng/treetable';
import {FreeMonthsUpdateComponent} from './post-order/free-months-update/free-months-update.component';
import {CouponsComponent} from './setup/coupons/coupons.component';
import {MaintainGroupsComponent} from './setup/users/maintain-groups/maintain-groups.component';
import {CreateNotificationComponent} from './notifications/create-notification/create-notification.component';
import {DisplayNotificationsComponent} from './notifications/display-notifications/display-notifications.component';
import {NavBarComponent} from './nav-bar/nav-bar.component';
import {AutoEnrolmentComponent} from './messages-list/message-detail/auto-enrolment/auto-enrolment.component';
import {CancellationCommsComponent} from './messages-list/message-detail/cancellation-comms/cancellation-comms.component';
import {CancellationInvoiceComponent} from './messages-list/message-detail/cancellation-invoice/cancellation-invoice.component';
import {MessageUpdatesSocketService} from './sockets/message-updates-socket.service';
import {OrderUpdatesSocketService} from './sockets/order-updates-socket.service';
import {NotificationsSocketService} from './sockets/notifications-socket.service';
import {LocksSocketService} from './sockets/locks-socket.service';
import {CreditControlActionUpdatesSocketService} from './sockets/credit-control-action-updates-socket.service';
import {AddEquipmentFaultComponent} from './post-order/faulty-equipments/add-equipment-fault/add-equipment-fault.component';
import {DisplayFaultyEquipmentsComponent} from './post-order/faulty-equipments/display-faulty-equipments/display-faulty-equipments.component';
import {DetailFaultyEquipmentComponent} from './post-order/faulty-equipments/detail-faulty-equipment/detail-faulty-equipment.component';
import {TooltipsComponent} from './setup/tooltips/tooltips.component';
import {RenewalCommsComponent} from './messages-list/message-detail/renewal-comms/renewal-comms.component';
import {AutoResizeTextareaDirective} from './directives/auto-resize-textarea.directive';
import {ReplacementItemComponent} from './post-order/replacement-item/replacement-item.component';
import {MaintainRolesComponent} from './setup/maintain-roles/maintain-roles.component';
import {AddRoleComponent} from './setup/maintain-roles/add-role/add-role.component';
import {HardwareComponent} from './setup/hardware/hardware.component';
import {LeadsComponent} from './leads/leads.component';
import {AddLeadComponent} from './leads/add-lead/add-lead.component';
import {UpdateLeadComponent} from './leads/update-lead/update-lead.component';
import {ActionsComponent} from './setup/actions/actions.component';
import {AddActionComponent} from './setup/actions/add-action/add-action.component';
import {HardwareSetComponent} from './setup/hardware-set/hardware-set.component';
import {BusinessGrowthCustomersComponent} from './business-growth-customers/business-growth-customers.component';
import {BusinessGrowthCustomerComponent} from './business-growth-customers/business-growth-customer/business-growth-customer.component';
import {PostSheetsComponent} from './post-sheets/post-sheets.component';
import {AddPostSheetComponent} from './post-sheets/add-post-sheet/add-post-sheet.component';
import {RenewalLinkComponent} from './setup/renewal-link/renewal-link.component';
import {CancellationLinkComponent} from './setup/cancellation-link/cancellation-link.component';
import {EmailSendComponent} from './post-order/email-send/email-send.component';
import {MailingLabelsComponent} from './post-sheets/mailing-labels/mailing-labels.component';
import {HighUserComponent} from './messages-list/message-detail/high-user/high-user.component';
// TODO remove for hardware version
import {RenewalPriceComponent} from './post-order/renewal-price/renewal-price.component';
import {PostSheetItemReportComponent} from './post-sheets/post-sheet-item-report/post-sheet-item-report.component';
import {PostSheetItemChartComponent} from './post-sheets/post-sheet-item-chart/post-sheet-item-chart.component';
import {PostSheetInfopackReportComponent} from './post-sheets/post-sheet-infopack-report/post-sheet-infopack-report.component';
import {PostSheetInfopackChartComponent} from './post-sheets/post-sheet-infopack-chart/post-sheet-infopack-chart.component';
import {RefundRequestComponent} from './post-order/refund-request/refund-request.component';
import {PostOrderDeactivateGuard} from './post-order/post-order-deactivate.guard';
import {PriceBookComponent} from './post-order/price-book/price-book.component';
import {HardwareAuditComponent} from './setup/hardware-audit/hardware-audit.component';
import {JontekContactsSectionComponent} from './post-order/jontek-contacts-section/jontek-contacts-section.component';
import {PlanTypeToPeriodPipe} from './pipes/plan-type-to-period.pipe';
import {MaintainAnnouncementComponent} from './setup/maintain-announcement/maintain-announcement.component';
import {EmailNoteComponent} from './post-order/email-note/email-note.component';
import {LetterSendComponent} from './post-order/letter-send/letter-send.component';
import {MessageVariationsPipe} from './pipes/message-variations.pipe';
import {CustomerFeedbackComponent } from './customer-feedback/customer-feedback.component';
import {EditCustomerFeedbackComponent} from './customer-feedback/edit-customer-feedback/edit-customer-feedback.component';
import {AddCustomerFeedbackComponent} from './customer-feedback/add-customer-feedback/add-customer-feedback.component';
import {CustomerFeedbackSectionComponent} from './post-order/customer-feedback-section/customer-feedback-section.component';
import {ReviewsSectionComponent} from './post-order/reviews-section/reviews-section.component';
import {OrderLinksSectionComponent} from './post-order/order-links-section/order-links-section.component';
import {CustomerFeedbackSocketService} from './sockets/customer-feedback-socket.service';
import {SmsSendComponent} from './post-order/sms-send/sms-send.component';
import {InitialOrderDetailsSectionComponent} from './post-order/initial-order-details-section/initial-order-details-section.component';
import {InitialDispatchSectionComponent} from './post-order/initial-dispatch-section/initial-dispatch-section.component';
import {CancellationSectionComponent} from './post-order/cancellation-section/cancellation-section.component';
import { RetentionDialogComponent } from './post-order/retention-dialog/retention-dialog.component';
import { SerialToManualPipe } from './pipes/serial-to-manual.pipe';
import { EquipmentManualsComponent } from './setup/equipment-manuals/equipment-manuals.component';
import {SerialToNamePipe} from './pipes/serial-to-name.pipe';
import { CustomerFeedbackFilterPipe } from './pipes/customer-feedback-filter.pipe';
import {PlanCodeHasTypesPipe} from './pipes/plan-code-has-types.pipe';
import {PlanCodeTypesPipe} from './pipes/plan-code-types.pipe';
import { AccountServicesComponent } from './setup/account-services/account-services.component';
import { FriendsAndFamilyContentComponent } from './setup/friends-and-family-content/friends-and-family-content.component';
import { MaintainFAndFContentComponent } from './setup/friends-and-family-content/maintain-f-and-f-content/maintain-f-and-f-content.component';
import { CatidToNamePipe } from './pipes/catid-to-name.pipe';
import { AlarmActivationSectionComponent } from './post-order/alarm-activation-section/alarm-activation-section.component';
import { MessageReportComponent } from './post-order/message-report/message-report.component';
import { GenesysComponent } from './genesys/genesys.component';
import { CancellingDialogComponent } from './post-order/cancelling-dialog/cancelling-dialog.component';
import { ThirdPartyPaymentDialogComponent } from './post-order/third-party-payment-dialog/third-party-payment-dialog.component';
import { HomepageNotesToFieldPipe } from './pipes/homepage-notes-to-field.pipe';
import { LegalCompanyToClassPipe } from './pipes/legal-company-to-class.pipe';
import { AddNoteComponent } from './post-order/add-note/add-note.component';
import { IsActiveStatusPipe } from './pipes/is-active-status.pipe';
import { StatusDisplaySectionPipe } from './pipes/status-display-section.pipe';
import { StripeDashboardLinkPipe } from './pipes/payment/stripe-dashboard-link.pipe';
import { StripeAccountNamePipe } from './pipes/payment/stripe-account-name.pipe';
import { SmartDebitAccountNamePipe } from './pipes/payment/smart-debit-account-name.pipe';
import { CseOrderReportComponent } from './reporting/cse-order-report/cse-order-report.component';
import { OrderAsAtComponent } from './reporting/order-as-at/order-as-at.component';
import { NcfDialogComponent } from './post-order/ncf-dialog/ncf-dialog.component';
import {ShopifyIdPipe} from './pipes/shopify/shopify-id.pipe';
import {ShopifyOrderComponent} from './cse-order/shopify-order/shopify-order.component';
import {WoocommerceOrderComponent} from './cse-order/woocommerce-order/woocommerce-order.component';
import {ShopifyProductToTypePipe} from './pipes/shopify/shopify-product-to-type.pipe';

@NgModule({ 
    declarations: [
        AppComponent,
        MessageComponent,
        RegistrationComponent,
        LoginComponent,
        ProfileComponent,
        SettingsComponent,
        WebsitesComponent,
        PostOrderComponent,
        MyOrdersComponent,
        ProductsComponent,
        TagsComponent,
        FilterPipe,
        FilterlogPipe,
        HighlightPipe,
        UsersComponent,
        PermissionComponent,
        FeedbackComponent,
        LogsComponent,
        ImportLogComponent,
        CseOrderComponent,
        CouponsComponent,
        HomeOutboundComponent,
        OutstandingActionComponent,
        OutstandingActionsListComponent,
        ContactAttemptComponent,
        RenewalUpdateComponent,
        FrozenPriceComponent,
        // TODO remove for hardware version
        RenewalPriceComponent,
        MessagesListComponent,
        MessageDetailComponent,
        FreeMonthsUpdateComponent,
        MaintainGroupsComponent,
        CreateNotificationComponent,
        DisplayNotificationsComponent,
        NavBarComponent,
        AutoEnrolmentComponent,
        RenewalCommsComponent,
        CancellationCommsComponent,
        CancellationInvoiceComponent,
        AddEquipmentFaultComponent,
        DisplayFaultyEquipmentsComponent,
        DetailFaultyEquipmentComponent,
        TooltipsComponent,
        AutoResizeTextareaDirective,
        ReplacementItemComponent,
        MaintainRolesComponent,
        AddRoleComponent,
        HardwareComponent,
        HardwareSetComponent,
        HardwareAuditComponent,
        LeadsComponent,
        AddLeadComponent,
        UpdateLeadComponent,
        ActionsComponent,
        AddActionComponent,
        BusinessGrowthCustomersComponent,
        BusinessGrowthCustomerComponent,
        RenewalLinkComponent,
        PostSheetsComponent,
        AddPostSheetComponent,
        CancellationLinkComponent,
        EmailSendComponent,
        MailingLabelsComponent,
        HighUserComponent,
        PostSheetItemReportComponent,
        PostSheetItemChartComponent,
        PostSheetInfopackReportComponent,
        PostSheetInfopackChartComponent,
        RefundRequestComponent,
        PriceBookComponent,
        JontekContactsSectionComponent,
        PlanCodeHasTypesPipe,
        PlanCodeTypesPipe,
        PlanTypeToPeriodPipe,
        MaintainAnnouncementComponent,
        EmailNoteComponent,
        LetterSendComponent,
        MessageVariationsPipe,
        CustomerFeedbackComponent,
        EditCustomerFeedbackComponent,
        AddCustomerFeedbackComponent,
        CustomerFeedbackSectionComponent,
        ReviewsSectionComponent,
        OrderLinksSectionComponent,
        SmsSendComponent,
        InitialOrderDetailsSectionComponent,
        InitialDispatchSectionComponent,
        CancellationSectionComponent,
        RetentionDialogComponent,
        SerialToManualPipe,
        SerialToNamePipe,
        EquipmentManualsComponent,
        CustomerFeedbackFilterPipe,
        AccountServicesComponent,
        FriendsAndFamilyContentComponent,
        MaintainFAndFContentComponent,
        CatidToNamePipe,
        AlarmActivationSectionComponent,
        MessageReportComponent,
        GenesysComponent,
        CancellingDialogComponent,
        ThirdPartyPaymentDialogComponent,
        HomepageNotesToFieldPipe,
        LegalCompanyToClassPipe,
        AddNoteComponent,
        IsActiveStatusPipe,
        StatusDisplaySectionPipe,
        StripeDashboardLinkPipe,
        StripeAccountNamePipe,
        SmartDebitAccountNamePipe,
        CseOrderReportComponent,
        OrderAsAtComponent,
        NcfDialogComponent,
        ShopifyIdPipe,
        ShopifyOrderComponent,
        WoocommerceOrderComponent,
        ShopifyProductToTypePipe,
    ],
    bootstrap: [AppComponent], 
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule, // required animations module
        TableModule,
        PaginatorModule,
        ButtonModule,
        CalendarModule,
        InputTextModule,
        MultiSelectModule,
        ListboxModule,
        RadioButtonModule,
        DialogModule,
        DropdownModule,
        BlockUIModule,
        PanelModule,
        ConfirmDialogModule,
        ToastModule,
        SidebarModule,
        ScrollPanelModule,
        TreeTableModule,
        ProgressBarModule,
        ProgressSpinnerModule,
        BrowserModule,
        ToggleButtonModule,
        AccordionModule,
        TabViewModule,
        DataViewModule,
        CardModule,
        StepsModule,
        SelectButtonModule,
        MessagesModule,
        MessageModule,
        DragDropModule,
        CheckboxModule,
        ChartModule
    ], 
    providers: [
        DataService,
        AuthGuardService,
        PostOrderDeactivateGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationService,
            multi: true
        },
        CreditControlActionUpdatesSocketService,
        CustomerFeedbackSocketService,
        LocksSocketService,
        MessageUpdatesSocketService,
        NotificationsSocketService,
        OrderUpdatesSocketService,
        provideHttpClient(withInterceptorsFromDi()),
    ]
})

export class AppModule {}
