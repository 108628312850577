import moment from 'moment-timezone';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {BaseOrderInformation} from '../../models/cseOrder/baseOrderInformation.model';
import {BusinessGrowthCustomer} from '../../models/businessGrowthCustomer.model';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {ProcessingSteps} from '../../models/cseOrder/processingSteps.model';
import {ProductsService} from '../../setup/products/products.service';
import {CouponsService} from '../../setup/coupons/coupons.service';
import {OrderService} from '../../post-order/order.service';
import {NotificationService} from '../../notifications/notification.service';
import {LeadsService} from '../../leads/leads.service';
import {Router} from '@angular/router';
import {shopifyVatStatuses} from '../../lookups/vatStatuses';
import {OrderVatStatus} from '../../lookups/cseOrder/orderVatStatus';
import {getHowHeardOptions} from '../../lookups/howHeard';
import {getShopifyProcessingSteps} from '../../lookups/cseOrder/shopify/processingSteps';
import {SHOPIFY_PRODUCT_CATEGORY_OPTS} from '../../lookups/productCategories';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {PopulatedShopifyVariant, RawShopifyProduct, RawShopifyVariant, ShopifyVariantComponent} from '../../models/shopifyProduct.model';
import {ShopifyDiscount} from '../../models/shopifyDiscount.model';
import {CalculationMethod, OrderType} from '../../lookups/cseOrder/cseOrderTypes';
import {ShopifyBasketItem} from '../../models/cseOrder/shopify/shopifyBasketItem.model';
import {ShopifyRestCustomer} from '../../models/cseOrder/shopify/shopifyCustomer.model';
import {CustomerEmailMarketingState, CustomerMarketingOptInLevel, CustomerSmsMarketingState, shopifyEmailOptInSelects, shopifySmsOptInSelects} from '../../lookups/cseOrder/shopify/marketing';
import {OrderMetadata} from '../../models/cseOrder/shopify/orderMedatadata.model';
import getAddressClient, {FindFailed, FindSuccess, Result} from 'getaddress-api';
import {Address, AlarmUserAddress} from '../../models/address.model';
import {environment} from '../../../environments/environment';
import {getLookupFromGetAddressResult, validateAddress} from '../../helpers/getAddressHelper';
import {DropDownChangeEvent} from '../../models/primeng/dropdownChangeEvent.model';
import {ShopifyRestOrder} from '../../models/cseOrder/shopify/shopifyRestOrder.model';
import {ProcessingError} from '../../lookups/cseOrder/processingError';
import {EX_TO_INC_VAT_MULTIPLIER, roundToTwoDecimalPlaces, VAT_MULTIPLIER} from '../../helpers/helperFunctions';
import {formatPhoneNumberInE164Format, isValidAnyCountryPhoneNumber} from '../../validators/phone-number.validator';
import {Order} from '../../models/order.model';
import {loadStripe, PaymentMethodResult, Stripe, StripeCardElement, StripeCardElementChangeEvent, StripeCardElementOptions, StripeElements, StripeError} from '@stripe/stripe-js';
import ServerSideStripe from 'stripe';
import {isValidEmailAddress} from '../../validators/email.validator';
import {SetupFeeConfig} from '../../models/cseOrder/setupFeeConfig.model';
import {SHOPIFY_SETUP_FEE_CONFIGS} from '../../lookups/cseOrder/shopify/setupFeeConfigs';
import {CseOrder} from '../../models/reporting/cseOrder.model';
import {Website} from '../../models/website.model';
import {SetupFeeRequired} from '../../lookups/cseOrder/setupFeeRequired';
import {StringIndexedObject} from '../../models/utility/stringIndexedObject.model';
import {CreatePaymentIntentRequest} from '../../models/requests/createPaymentIntentRequest.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {STRIPE_ACCOUNTS} from '../../lookups/stripeAccounts';
import {ExternalId} from '../../models/externalId.model';
import {FindOrderResponse} from '../../models/responses/findOrderResponse.model';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {ShopifyRestOrderLine} from '../../models/cseOrder/shopify/shopifyRestOrderLine.model';
import {OrderResponse} from '../../models/responses/orderResponse.model';

@Component({
  selector: 'app-shopify-order[baseOrderInformation][partnerships][partnershipByVoucherCode][updateShowPlaceOrder]',
  standalone: false,
  templateUrl: './shopify-order.component.html',
  styleUrls: ['../cse-order.component.scss', './shopify-order.component.scss'],
})
export class ShopifyOrderComponent implements OnInit, AfterViewInit{
  @ViewChild('cardInfo') cardInfo: ElementRef;
  @Input() baseOrderInformation: BaseOrderInformation;
  @Input() partnerships: SelectItem<BusinessGrowthCustomer>[];
  @Input() partnershipByVoucherCode: {[code: string]: BusinessGrowthCustomer};
  @Output() updateShowPlaceOrder: EventEmitter<boolean> = new EventEmitter<boolean>();
  Object = Object;
  numberOnly = numberOnly;
  OrderVatStatusEnum = OrderVatStatus;
  readonly ORDER_CREATION_STEP: string = 'Creating Order on Alarm Website';

  vatOptions: SelectItem<boolean>[];
  vatSelected: boolean;
  category: string;
  categories: SelectItem<string>[];
  notExemptCategories: SelectItem<string>[];
  exemptCategories: SelectItem<string>[];
  cseProducts: RawShopifyProduct[];
  productMap: StringIndexedObject<RawShopifyProduct>;
  variantMap: StringIndexedObject<PopulatedShopifyVariant>;
  productsFiltered: RawShopifyProduct[];
  selectedProduct: RawShopifyProduct;
  variantsFiltered: RawShopifyVariant[];
  selectedVariant: RawShopifyVariant;
  orderVatStatus: OrderVatStatus;
  orderRenewalPeriod: string;
  orderBeingCreated: boolean;
  shopifyItems: ShopifyBasketItem[];
  userName: string;
  howHeardOptions: SelectItem<string>[];
  vatTotal: number;
  orderTotal: number;
  totalBeforeDiscountAndOverride: number;
  discountAmount: number;
  hasRentalItems: boolean;
  discountCode: string;
  discountCodeError: string;
  calculationError: string;
  totalOverridden: boolean;
  lifetimeLineNeedsOverride: boolean;
  paymentMethod: string;
  currencySymbol: string;
  discounts: ShopifyDiscount[];
  discount: ShopifyDiscount;
  selectedPartnership: BusinessGrowthCustomer;
  validationErrors: string[];
  shopifyCustomer: ShopifyRestCustomer;
  shopifyOrder: ShopifyRestOrder;
  alarmUserAddress: AlarmUserAddress;
  alarmUserAddressOption: string;
  alarmUserNameOption: string;
  alarmUserPhoneOption: string;
  orderMetadata: OrderMetadata;
  getAddrClient: getAddressClient;
  billingSearchPostCode: string;
  deliverySearchPostCode: string;
  alarmUserSearchPostCode: string;
  billingAddressResults: SelectItem<Address>[];
  deliveryAddressResults: SelectItem<Address>[];
  alarmUserAddressResults: SelectItem<Address>[]; 
  allowBillingAddressManualEntry: boolean;
  allowDeliveryAddressManualEntry: boolean;
  allowAlarmUserAddressManualEntry: boolean;
  billingSearchError: string;
  deliverySearchError: string;
  alarmUserSearchError: string;
  differentDeliver: boolean;
  emailOptInSelects: SelectItem<CustomerEmailMarketingState>[];
  smsOptInSelects: SelectItem<CustomerSmsMarketingState>[];
  processingSteps: ProcessingSteps;
  showProgressBar: boolean;
  currentStep: string;
  orderCreationSuccess: boolean;
  processingError: ProcessingError;
  displayErrorDetails: boolean;
  draftOrderId: string;
  orderLink: string;
  websiteOrderId: string;
  crmOrderLink: string;
  orderDateYMD: string;
  recentOrder: boolean;
  stripeBrand: string;
  stripe: Stripe;
  cardElement: StripeCardElement;
  cardHandler = this.onChange.bind(this);
  stripeCardError: string;
  cseOrder: CseOrder;
  baseUnitTypes: string[];
  referralTdFound: boolean;
  referralTDCode: string;
  referralTdSearched: boolean;

  constructor(
    private productsService: ProductsService,
    private couponsService: CouponsService,
    private changeDetector: ChangeDetectorRef,
    private orderService: OrderService,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService,
    private leadService: LeadsService,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.vatOptions = shopifyVatStatuses;
    this.emailOptInSelects = shopifyEmailOptInSelects;
    this.smsOptInSelects = shopifySmsOptInSelects;
    this.orderVatStatus = OrderVatStatus.NOT_SET;
    this.showProgressBar = false;
    this.processingError = null;
    this.displayErrorDetails = false;
    this.vatSelected = null;
    this.category = null;
    this.categories = [];
    this.cseProducts = [];
    this.productMap = {};
    this.variantMap = {};
    this.productsFiltered = [];
    this.variantsFiltered = [];
    this.selectedProduct = null;
    this.selectedVariant = null;
    this.orderRenewalPeriod = null;
    this.orderBeingCreated = false;
    this.shopifyItems = [];
    this.vatTotal = 0;
    this.orderTotal = 0;
    this.totalBeforeDiscountAndOverride = 0;
    this.discountAmount = 0;
    this.hasRentalItems = false;
    this.discountCode = '';
    this.discountCodeError = '';
    this.calculationError = '';
    this.totalOverridden = false;
    this.lifetimeLineNeedsOverride = false;
    this.paymentMethod = '';
    this.processingSteps = getShopifyProcessingSteps();
    this.orderCreationSuccess = false;
    this.notExemptCategories = SHOPIFY_PRODUCT_CATEGORY_OPTS;
    this.exemptCategories = this.notExemptCategories.filter((selectItem: SelectItem<string>) => 
      (selectItem.value != 'Key Safes')
    );
    this.partnerships = [];
    this.partnershipByVoucherCode = {};
    this.userName = localStorage.getItem('userName');
    this.howHeardOptions = getHowHeardOptions();
    this.selectedPartnership = undefined;
    this.validationErrors = [];
    this.draftOrderId = '';
    this.orderLink = '';
    this.crmOrderLink = '';
    this.referralTdFound = false;
    this.referralTDCode = '';
    this.referralTdSearched = false;
    this.shopifyCustomer = {
      'first_name': '',
      'last_name': '',
      'phone': '',
      'email': '',
      'send_email_invite': false,
      'emailMarketingConsent': {
        'marketingOptInLevel': CustomerMarketingOptInLevel.SINGLE_OPT_IN,
        'marketingState': null,
        'consentUpdatedAt': this.baseOrderInformation.currentMoment.toDate(),
      },
      'smsMarketingConsent': {
        'marketingOptInLevel': CustomerMarketingOptInLevel.SINGLE_OPT_IN,
        'marketingState': null,
        'consentUpdatedAt': this.baseOrderInformation.currentMoment.toDate(),
      },
      'addresses': [],
    };
    this.alarmUserAddress = {
      'addressOne': '',
      'addressTwo': '',
      'city': '',
      'county': '',
      'postcode': '',
      'validated': false,
      'unknown': false,
    };
    this.orderMetadata = {
      'ncfRequired': 'Yes',
      'billingAddressValidated': false,
      'shippingAddressValidated': false,
      'alarmUserFirstName': '',
      'alarmUserLastName': '',
      'alarmUserMobile': '',
      'alarmUserPhone': '',
      'paymentMethod': '',
      'renewalMethod': '',
      'nameOnCard': '',
    };
    this.shopifyOrder = {
      'customer': {
        'id': '',
      },
      'currency': 'GBP',
      'billing_address': {
        'first_name': '',
        'last_name': '',
        'address1': '',
        'address2': '',
        'city': '',
        'province': '',
        'zip': '',
        'country_code': 'GB',
      },
      'shipping_address': {
        'first_name': '',
        'last_name': '',
        'address1': '',
        'address2': '',
        'city': '',
        'province': '',
        'zip': '',
        'country_code': 'GB',
      },
      'phone': '',
      'send_receipt': false,
      'line_items': [],
      'note_attributes': [],
      'discount_codes': [],
      'note': '',
      'financial_status': 'pending',
      'transactions': [],
      'total_tax': 0.00,
    };
    this.differentDeliver = false;
    this.currencySymbol = '\u00A3';
    if (this.selectedWebsite.title == 'LLIE') {
      this.currencySymbol = '\u20AC';
      this.shopifyOrder.billing_address.country_code = 'IE';
      this.shopifyOrder.shipping_address.country_code = 'IE';
      this.shopifyOrder.currency = 'EUR';
    }
    this.stripeBrand = this.selectedWebsite.title;
    if (this.order && (this.order.legalCompany == 'Lifeline24 Ltd')) {
      this.stripeBrand = `${this.selectedWebsite.title}_OLD`
      this.shopifyOrder.note_attributes.push({
        'name': 'stripe.account_id',
        'value': STRIPE_ACCOUNTS['Lifeline24 Ltd'][this.selectedWebsite.title].accountId,
      });
      this.shopifyOrder.note_attributes.push({
        'name': 'legal_entity',
        'value': 'Lifeline24 Ltd',
      });
    } else {
      this.shopifyOrder.note_attributes.push({
        'name': 'stripe.account_id',
        'value': STRIPE_ACCOUNTS['Careline365 Ltd'][this.selectedWebsite.title].accountId,
      });
      this.shopifyOrder.note_attributes.push({
        'name': 'legal_entity',
        'value': 'Careline365 Ltd',
      });
    }
    this.discounts = [];
    this.discount = undefined;
    this.billingSearchPostCode = '';
    this.deliverySearchPostCode = '';
    this.alarmUserSearchPostCode = '';
    this.billingAddressResults = [];
    this.deliveryAddressResults = [];
    this.alarmUserAddressResults = [];
    this.allowBillingAddressManualEntry = false;
    this.allowDeliveryAddressManualEntry = false;
    this.allowAlarmUserAddressManualEntry = false;
    this.getAddrClient = new getAddressClient(environment.getAddressDomainToken);

    this.productsService.getAllShopifyProductsForSite(this.selectedWebsite._id).subscribe({
      next: (response: MultiRecordResponse<RawShopifyProduct>) => {
        if (!response.success) {
          console.error('Error getting product data');
        } else {
          response.data.forEach((rawProduct: RawShopifyProduct) => {
            if (rawProduct.status == 'archived') {
              return;
            }
            if (rawProduct.displayOnCseOrderPage == 'yes') {
              this.cseProducts.push(rawProduct);
            }
            this.productMap[rawProduct.shopifyProductId] = rawProduct;
          });
        }
      },
      error: (err: any) => {
        console.error('Error getting product data', err);
      }
    });
    this.productsService.getAllShopifyVariantsForSite(this.selectedWebsite._id).subscribe({
      next: (response: MultiRecordResponse<PopulatedShopifyVariant>) => {
        if (!response.success) {
          console.error('Error getting variant data');
        } else {
          response.data.forEach((populatedVariant: PopulatedShopifyVariant) => {
            this.variantMap[populatedVariant.shopifyVariantId] = populatedVariant;
          });
        }
      },
      error: (err: any) => {
        console.error('Error getting variant data', err);
      }
    });
    this.couponsService.getShopifyDiscounts(this.selectedWebsite._id).subscribe({
      next: (discountResponse: MultiRecordResponse<ShopifyDiscount>) => {
        this.discounts = discountResponse.data;
      },
      error: (err: any) => {
        console.error('Error getting discount data', err);
      }
    });
    this.baseUnitTypes = [];
    this.initialiseFromNavigationData();
    this.initialiseFromExistingOrder();
    this.showPlaceOrder = true;
  }

  onChange(event: StripeCardElementChangeEvent) {
    if (event.error) {
      this.stripeCardError = event.error.message;
    } else {
      this.stripeCardError = null;
    }
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    const stripeCardElementOptions: StripeCardElementOptions = {
      'style': {
        'base': {
          'fontFamily': 'monospace',
          'fontSmoothing': 'antialiased',
          'fontSize': '19px',
          '::placeholder': {
            'color': 'purple'
          }
        }
      }
    };
    loadStripe(environment.stripeKeys[this.stripeBrand]).then((tmpStripe: Stripe) => {
      this.stripe = tmpStripe;
      const stripeElements: StripeElements = this.stripe.elements();
      this.cardElement = stripeElements.create('card', stripeCardElementOptions);
      this.cardElement.mount(this.cardInfo.nativeElement);
      this.cardElement.on('change', this.cardHandler);
    }).catch((error: any) => {
      this.showErrorPopUp('Error Loading Stripe Card Entry',
        `Error loading the Stripe card entry please refresh the page to try again. Reason: ${error.message? error.message: 'unknown error'}`
      );
    });
    
  }

  initialiseFromNavigationData() {
    if (!this.baseOrderInformation.cseOrderNavigationData) {
      return;
    }
    this.alarmUserNameOption = 'other';
    this.alarmUserPhoneOption = 'other';
    this.alarmUserAddressOption = 'other';
    this.orderMetadata.alarmUserFirstName = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.firstName;
    this.orderMetadata.alarmUserLastName = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.lastName;
    this.orderMetadata.alarmUserPhone = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.telephone;
    this.orderMetadata.alarmUserMobile = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.mobile;
    this.alarmUserAddress = {
      'addressOne': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.addressOne,
      'addressTwo': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.addressTwo,
      'city': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.city,
      'county': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.county,
      'postcode': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.postcode,
      'validated': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.validated,
      'unknown': false,
    };
    this.customerFirstName = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.firstName;
    this.customerLastName = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.lastName;
    this.shopifyCustomer.email = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.email;
    if (this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.mobile) {
      this.shopifyCustomer.phone = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.mobile;
    } else if (!this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.mobile && this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.telephone) {
      this.shopifyCustomer.phone = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.telephone;
    }
    this.shopifyOrder.billing_address.address1 = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.addressOne;
    this.shopifyOrder.billing_address.address2 = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.addressTwo;
    this.shopifyOrder.billing_address.city = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.city;
    this.shopifyOrder.billing_address.province = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.county;
    this.shopifyOrder.billing_address.zip = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.postcode;
    this.orderMetadata.billingAddressValidated = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.validated;
    this.validateBillingAddress(true);
  }

  initialiseFromExistingOrder() {
    if (!this.order) {
      return;
    }
    this.baseOrderInformation.cseOrder.orderId = this.order._id;
    this.baseOrderInformation.cseOrder.tdCode = this.order.alarmUserDetails.tdCode;
    this.baseOrderInformation.cseOrder.currentPlan = this.order.accountDetails.plan;
    this.orderMetadata.ncfRequired = 'No';
    this.crmOrderLink = '/order/' + this.order['_id'];
    this.shopifyCustomer.email = this.order.alarmUserDetails.email;
    this.shopifyCustomer.phone = this.order.alarmUserDetails.mobile? this.order.alarmUserDetails.mobile: this.order.alarmUserDetails.telephone;
    this.customerFirstName =  this.order.alarmUserDetails.firstName;
    this.customerLastName = this.order.alarmUserDetails.lastName;
    this.shopifyOrder.billing_address.address1 = this.order.alarmUserDetails.userAddress.addressOne;
    this.shopifyOrder.billing_address.address2 = this.order.alarmUserDetails.userAddress.addressTwo;
    this.shopifyOrder.billing_address.city = this.order.alarmUserDetails.userAddress.city;
    this.shopifyOrder.billing_address.province = this.order.alarmUserDetails.userAddress.county;
    this.shopifyOrder.billing_address.zip = this.order.alarmUserDetails.userAddress.postcode;
    this.orderMetadata.billingAddressValidated = this.order.alarmUserDetails.userAddress.validated;
    const createdMoment: moment.Moment = moment.tz(this.order.created, 'Europe/London');
    this.orderDateYMD = createdMoment.format('YYYY-MM-DD');
    this.recentOrder = (this.baseOrderInformation.currentMoment.diff(createdMoment, 'months') <= 11);
    switch (this.planType) {
      case 'annual':
        this.orderRenewalPeriod = '12M';
        break;
      case 'quarterly':
        this.orderRenewalPeriod = '3M';
        break;
      case 'monthly':
        this.orderRenewalPeriod = '1M';
        break;
      case 'lifetime':
        this.orderRenewalPeriod = 'LIFE';
        break;
    }
    if (this.order.accountDetails && this.order.accountDetails.plan) {
      // Only care about existing alarm base unit type for Additional, Lost or Free order types
      if (['Additional', 'Lost', 'Free'].includes(this.selectedOrderType.title)) {
        const planCode: string = this.order.accountDetails.plan;
        if (/1B/.test(planCode)) {
          this.baseUnitTypes.push('Pebbell GPS');
        }
        if (/1T/.test(planCode)) {
          this.baseUnitTypes.push('Chiptech GO GPS');
        }
        if (/1P/.test(planCode)) {
          this.baseUnitTypes.push('Tynetec IP');
        }
        if (/1G/.test(planCode)) {
          this.baseUnitTypes.push('Tunstall GSM');
        }
        if (/1E/.test(planCode)) {
          this.baseUnitTypes.push('EVA GSM');
        }
        if (/17/.test(planCode)) {
          this.baseUnitTypes.push('SEVEN GSM');
        }
        if (/1Z/.test(planCode)) {
          this.baseUnitTypes.push('SmartLife');
        }
        if (/1([^BTPGE7Z]|$)/.test(planCode)) {
          this.baseUnitTypes.push('Lifeline VI');
        }
      }
      if (this.order.accountDetails.plan.includes('V')) {
        this.vatSelected = true;
        this.orderVatStatus = OrderVatStatus.VATABLE;
      } else {
        this.vatSelected = false;
        this.orderVatStatus = OrderVatStatus.VAT_EXEMPT;
      }
      this.setVat();
    }
    this.validateBillingAddress(false);
  }
  
  set showPlaceOrder(newValue: boolean) {
    this.orderBeingCreated = !newValue;
    this.updateShowPlaceOrder.emit(newValue);
  }

  get selectedWebsite(): Website {
    return (this.baseOrderInformation.cseOrder.websiteId as Website);
  }

  get showPlaceOrder(): boolean {
    return !this.orderBeingCreated;
  }

  get order(): Order {
    return this.baseOrderInformation.existingOrder;
  }

  set order(newValue: Order) {
    this.baseOrderInformation.existingOrder = newValue;
  }

  get planType(): string {
    return (this.order && this.order.accountDetails)? this.order.accountDetails.planType: '';
  }

  get selectedOrderType(): OrderType {
    return this.baseOrderInformation.orderType;
  }

  get validationErrorExists(): boolean {
    return this.validationErrors.length > 0;
  }

  set customerFirstName(newValue: string) {
    this.shopifyCustomer.first_name = newValue;
    this.shopifyOrder.billing_address.first_name = newValue;
  }

  get customerFirstName(): string {
    return this.shopifyCustomer.first_name;
  }

  set customerLastName(newValue: string) {
    this.shopifyCustomer.last_name = newValue;
    this.shopifyOrder.billing_address.last_name = newValue;
  }

  get customerLastName(): string {
    return this.shopifyCustomer.last_name;
  }

  set customerPhone(newValue: string) {
    const e164FormatNumber: string = formatPhoneNumberInE164Format(newValue);
    this.shopifyCustomer.phone = e164FormatNumber;
    this.shopifyOrder.phone = e164FormatNumber;
  }

  get customerPhone(): string {
    return this.shopifyCustomer.phone;
  }

  set alarmUserPhone(newValue: string) {
    const e164FormatNumber: string = formatPhoneNumberInE164Format(newValue);
    this.orderMetadata.alarmUserPhone = e164FormatNumber;
  }

  get alarmUserPhone(): string {
    return this.orderMetadata.alarmUserPhone;
  }

  set alarmUserMobile(newValue: string) {
    const e164FormatNumber: string = formatPhoneNumberInE164Format(newValue);
    this.orderMetadata.alarmUserMobile = e164FormatNumber;
  }

  get alarmUserMobile(): string {
    return this.orderMetadata.alarmUserMobile;
  }
  
  get overrideTotal(): boolean {
    return this.totalOverridden;
  }

  set overrideTotal(overridden: boolean) {
    // Total was overridden, but turned off, so reset prices
    if (!overridden && this.totalOverridden) {
      for (let shopifyItem of this.shopifyItems) {
        shopifyItem.regularPrice = shopifyItem.originalPrice;
      }
    }
    this.totalOverridden = overridden;
    this.priceupdate();
  }

  isExistingCustomer(): boolean {
    return !this.selectedOrderType.isNewOrder;
  }

  isAdditional(): boolean {
    return this.selectedOrderType && (this.selectedOrderType.calculationMethod == CalculationMethod.ADDITIONAL);
  }

  isReplacementDueToFault(): boolean {
    return this.selectedOrderType && (this.selectedOrderType.title == 'Faulty');
  }

  isAllowedDiscontinued(): boolean {
    return this.selectedOrderType &&
      ([CalculationMethod.FREE, CalculationMethod.LOST].includes(this.selectedOrderType.calculationMethod));
  }

  emailBlur(): void {
    if (this.shopifyCustomer.email) {
      this.orderMetadata.ncfRequired = 'No';
    }
  }

  setVat() {
    this.productsFiltered = this.cseProducts.filter((product: RawShopifyProduct) => 
      product.shopifyVariants.some((shopifyVariant: RawShopifyVariant) =>
        shopifyVariant.taxable === this.vatSelected,
      )
    );
    if (this.vatSelected == false) {
      if (this.orderVatStatus == OrderVatStatus.VATABLE) {
        // They can't choose anything from this category with VATable items in their basket
        this.categories = [];
      } else {
        this.categories = this.exemptCategories; // Ex keysafe
      }
    } else if (this.vatSelected == true) {
      if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
        // They have VAT exempt items in the order, so can only add keysafes from the VATable category
        this.categories = [{
          'label': 'Key Safe',
          'value': 'keySafe'
        }];
      } else {
        this.categories = this.notExemptCategories; // Inc keysafe
      }
    }
    this.categories = this.categories.filter((categorySelect: SelectItem<string>) => {
      if (['Discontinued', 'Base Unit', 'Cables/Chargers', 'GPS Alarm'].includes(categorySelect.value)) {
        return (this.isAllowedDiscontinued());
      }
      return true;
    });
  }

  setCategory() {
    this.setVat();
    this.productsFiltered = this.productsFiltered.filter((product: RawShopifyProduct) => {
      if (product.crmCategory != this.category) {
        return false;
      }
      // If the product is a linked accessory only allow it if it relates to an alarm in the order
      if (product.shopifyCollectionNames.some((collectionName: string) => collectionName.includes('Linked Accessories'))) {
        let allowedProduct: boolean = false;
        for (const baseUnitType of this.baseUnitTypes) {
          if (product.shopifyCollectionNames.includes(`${baseUnitType} Linked Accessories`)) {
            allowedProduct = true;
            break;
          }
        }
        if (!allowedProduct) {
          return false;
        }
      }
      if (this.orderRenewalPeriod) {
        return product.shopifyVariants.some((shopifyVariant: RawShopifyVariant) => {
          if (shopifyVariant.rentalPeriod == this.orderRenewalPeriod) {
            return true;
          }
          if (['none', ''].includes(shopifyVariant.rentalPeriod)) {
            return true;
          }
          return false;
        });
      }
      return true;
    });
    this.selectedProduct = null;
    this.variantsFiltered = [];
  }

  onOptionsSelected() {
    if (!this.selectedProduct) {
      this.selectedVariant = null;
      this.variantsFiltered = [];
      return;
    }
    this.variantsFiltered = this.selectedProduct.shopifyVariants.filter((variant: RawShopifyVariant) => {
      if (variant.taxable != this.vatSelected) {
        return false;
      }
      if (this.orderRenewalPeriod && variant.rentalPeriod) {
        return variant.rentalPeriod == this.orderRenewalPeriod;
      }
      return true;
    });
    if (this.variantsFiltered.length == 1) {
      this.selectedVariant = this.variantsFiltered[0];
    } else {
      this.selectedVariant = null;
    }
  }

  updateFilters() {
    // Update the category list for changes to the VAT status and frequency of products in the basket
    if (this.category) {
      // This calls setVat, so no need to call that if category is set
      this.setCategory();
    } else if (this.vatSelected) {
      this.setVat();
    }
  }

  addToInvoice() {
    const selectedProd: ShopifyBasketItem = {
      'productId': this.selectedProduct._id,
      'shopifyProduct': this.selectedProduct,
      'shopifyVariant': this.variantMap[this.selectedVariant.shopifyVariantId],
      'regularPrice': this.selectedVariant.regularPrice,
      'originalPrice': this.selectedVariant.regularPrice,
      'quantity': 1,
      'priceCalculated': false,
      'subtotalExVat': 0,
      'subtotalVat': 0,
      'totalExVat': 0,
      'totalVat': 0
    };
    this.variantsFiltered = [];

    const pos: number = this.shopifyItems.findIndex((shopifyItem: ShopifyBasketItem) => 
      selectedProd.shopifyVariant.shopifyVariantId == shopifyItem.shopifyVariant.shopifyVariantId
    );
    if (pos == -1) {
      this.shopifyItems.push(selectedProd);
    } else {
      this.shopifyItems[pos].quantity += 1;
    }

    this.selectedVariant = null;
    this.selectedProduct = null;

    this.priceupdate();
    this.updateFilters();
  }

  priceupdate() {
    this.vatTotal = 0;
    this.orderTotal = 0;
    this.totalBeforeDiscountAndOverride = 0;
    this.calculationError = '';
    this.lifetimeLineNeedsOverride = false;
    this.hasRentalItems = false;
    if (this.baseOrderInformation.hasBulkTag) {
      this.freeOrderPriceUpdate();
    } else {
      switch (this.selectedOrderType.calculationMethod) {
        case CalculationMethod.FULL:
          this.newOrderPriceUpdate();
          break;
        // TODO add other calculation methods in
        // case CalculationMethod.ADDITIONAL:
        //   this.proRataPriceUpdate(false);
        //   break;
        // case CalculationMethod.DIFFERENCE:
        //   this.proRataPriceUpdate(true);
        //   break;
        // case CalculationMethod.FREE:
        //   this.freeOrderPriceUpdate();
        //   break;
        // case CalculationMethod.LOST:
        //   this.lostItemPriceUpdate();
        //   break;
      default:
          break;
      }
    }
    if (this.orderTotal == 0) {
      this.paymentMethod = 'No Payment Required';
    } else if ((this.orderTotal > 0) && (this.paymentMethod = 'No Payment Required')) {
      this.paymentMethod = '';
    }
  }
  
  setOrderFiltersAndFlags(item: ShopifyBasketItem) {
    if (!['', 'none'].includes(item.shopifyVariant.rentalPeriod)) {
      this.orderRenewalPeriod = item.shopifyVariant.rentalPeriod;
      this.hasRentalItems = true;
    }
    if (item.shopifyProduct.isBundle) {
      item.shopifyVariant.productVariantComponents.forEach((svc: ShopifyVariantComponent) => {
        const variant: PopulatedShopifyVariant = this.variantMap[svc.productVariant.id];
        // Use the hardware name as less likely to have been changed
        if (variant && variant.hardware && ['Base Unit', 'GPS Alarm'].includes(variant.hardware.category)) {
          if (!this.baseUnitTypes.includes(variant.hardware.title)) {
            this.baseUnitTypes.push(variant.hardware.title);
          }
        }
      });
    } else if (item.shopifyVariant.hardware && ['Base Unit', 'GPS Alarm'].includes(item.shopifyVariant.hardware.category)) {
      if (!this.baseUnitTypes.includes(item.shopifyVariant.hardware.title)) {
        this.baseUnitTypes.push(item.shopifyVariant.hardware.title);
      }
    }
  }

  freeOrderPriceUpdate() {
    this.baseUnitTypes = [];
    // Update appropriate prices to zero and check if fee needs to be removed
    // Calculations still happen in case price on any items has been overridden to be non-zero
    for (let currentItem of this.shopifyItems) {
      let unitCost: number = 0;
      let unitVat: number = 0;
      if (!currentItem.priceCalculated) {
        currentItem.regularPrice = 0.00;
        currentItem.originalPrice = 0.00;
        currentItem.priceCalculated = true;
      }
      unitCost = currentItem.regularPrice;
      if (currentItem.shopifyVariant.taxable) {
        unitVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * unitCost);
        this.vatTotal += roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
      }
      this.orderTotal += (unitCost + unitVat) * currentItem.quantity;
      currentItem.subtotalExVat = unitCost;
      currentItem.subtotalVat = unitVat;
      this.setOrderFiltersAndFlags(currentItem);
    }
  }

  newOrderPriceUpdate() {
    this.baseUnitTypes = [];
    let feeRequired: SetupFeeRequired = SetupFeeRequired.NO_FEE;
    this.orderVatStatus = OrderVatStatus.NOT_SET;
    this.orderRenewalPeriod = null;
    const setupFeeConfig: SetupFeeConfig = SHOPIFY_SETUP_FEE_CONFIGS[this.selectedWebsite._id];

    // Calculate undiscounted value for use in Coupon checks and check if fee needs to be applied
    for (let currentItem of this.shopifyItems) {
      // Reduced fee is the priority one to use, so don't change it if already set
      if ((setupFeeConfig) && (feeRequired != SetupFeeRequired.REDUCED_FEE)) {
        if (['Plans'].includes(currentItem.shopifyProduct.crmCategory) && !currentItem.shopifyVariant.crmVariantTitle.toLocaleLowerCase().includes('outlet')) {
          if (setupFeeConfig.productsRequiringReducedFee.includes(currentItem.shopifyProduct.shopifyProductId)) {
            feeRequired = SetupFeeRequired.REDUCED_FEE;
          } else {
            feeRequired = SetupFeeRequired.NORMAL_FEE;
          }
        }
      }
      let unitCost: number = 0;
      let unitVat: number = 0;
      let originalUnitCost: number = 0;
      let originalUnitVat: number = 0;
      // This will include VAT for VATable items
      unitCost = currentItem.regularPrice;
      originalUnitCost = currentItem.originalPrice;
      if (currentItem.shopifyVariant.taxable) {
        unitVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * unitCost);
        originalUnitVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * originalUnitCost);
        this.vatTotal += roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
        if (currentItem.shopifyProduct.crmCategory != 'Key Safes') {
          // Key safes are never exempt, so don't define the order status
          this.orderVatStatus = OrderVatStatus.VATABLE;
        }
      } else {
        // If they have at least 1 exempt item treat them as exempt (key safes are never exempt)
        this.orderVatStatus = OrderVatStatus.VAT_EXEMPT;
      }
      this.orderTotal += (unitCost + unitVat) * currentItem.quantity;
      this.totalBeforeDiscountAndOverride += (originalUnitCost + originalUnitVat) * currentItem.quantity;
      currentItem.subtotalExVat = unitCost;
      currentItem.subtotalVat = unitVat;
      this.setOrderFiltersAndFlags(currentItem);
    }
    // TODO add back in fee check
    // if (setupFeeConfig && this.updateFeeIfRequired(setupFeeConfig, feeRequired)) {
    //   // Exit this call of priceupdate as fee was udpated and will call this method again it once the the fee is updated
    //   return;
    // }

    console.log('Price before discount', this.orderTotal);
    // TODO add in discounting.
    // this.couponDiscount = this.TTorder;
    // if (!this.couponCode || !this.isCouponValid()) {
    //   this.convertCalculationItemsToWooItems(calculationItems);
    // } else {
    //   switch (this.coupon.type) {
    //     /*
    //       Percent amount off whole cart
    //     */
    //     case 'percent':
    //     /*
    //       Percent amount off specific product(s)
    //     */
    //     case 'percent_product':
    //       this.applyPercentDiscount(calculationItems);
    //       break;
    //     /*
    //       Currency amount off whole cart - divided across products in ratio of VAT inc value of each line
    //     */
    //     case 'fixed_cart':
    //       this.applyWholeCartFixedDiscount(calculationItems);
    //       break;
    //     /*
    //       Currency amount off specific product(s)
    //     */
    //     case 'fixed_product':
    //       this.applyProductFixedDiscount(calculationItems);
    //       break;
    //     default:
    //       // Straight convert, no discounts need applying
    //       this.convertCalculationItemsToWooItems(calculationItems);
    //       break;
    //   }
    // }
    // // Coupon discount is what the value was before, minus the value now
    // this.couponDiscount = this.couponDiscount - this.TTorder;
  }

  delete(i: number) {
    this.shopifyItems.splice(i, 1);
    this.priceupdate();
    this.updateFilters();
  }

  searchDiscount() {
    if (!this.discountCode) {
      this.discountCodeError = '';
      this.discount = undefined;
    }
    let matchingDiscount: ShopifyDiscount|undefined = undefined;
    for (let discount of this.discounts) {
      if (this.discountCode.toLocaleLowerCase() == discount.code.toLocaleLowerCase()) {
        matchingDiscount = discount;
        if (this.partnershipByVoucherCode[this.discountCode]) {
          this.baseOrderInformation.cseOrder.howHeard = 'Partnership';
          this.selectedPartnership = this.partnershipByVoucherCode[this.discountCode];
        }
      }
    }
    this.discount = matchingDiscount;
    this.priceupdate();
  }

  billingAddressSearch() {
    if (!this.billingSearchPostCode) {
      return;
    }
    this.billingAddressResults = [];
    this.allowBillingAddressManualEntry = false;
    this.billingSearchError = '';
    this.getAddrClient.find(this.billingSearchPostCode).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.billingAddressResults = getLookupFromGetAddressResult(this.billingSearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.billingSearchError = addressResult.toFailed().message;
        console.error('Billing Address search failed. Error:', this.billingSearchError);
      } else if (this.billingAddressResults.length <= 2) {
        this.billingSearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Billing Address search failed. Error:', error);
    });
  }

  setBillingAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowBillingAddressManualEntry = true;
      if (!this.shopifyOrder.billing_address.address1 && !this.shopifyOrder.billing_address.zip) {
        this.shopifyOrder.billing_address.zip = this.billingSearchPostCode;
      }
      this.orderMetadata.billingAddressValidated = false;
      return;
    }
    this.allowBillingAddressManualEntry = false;
    // Don't want to copy Role
    this.shopifyOrder.billing_address.address1 = selectedAddress.addressOne;
    this.shopifyOrder.billing_address.address2 = selectedAddress.addressTwo;
    this.shopifyOrder.billing_address.city = selectedAddress.city;
    this.shopifyOrder.billing_address.province = selectedAddress.county;
    this.shopifyOrder.billing_address.zip = selectedAddress.postcode;
    this.orderMetadata.billingAddressValidated = selectedAddress.validated;
  }

  deliveryAddressSearch() {
    if (!this.deliverySearchPostCode) {
      return;
    }
    this.deliveryAddressResults = [];
    this.allowDeliveryAddressManualEntry = false;
    this.deliverySearchError = '';
    this.getAddrClient.find(this.deliverySearchPostCode).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.deliveryAddressResults = getLookupFromGetAddressResult(this.deliverySearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.deliverySearchError = addressResult.toFailed().message;
        console.error('Delivery Address search failed. Error:', this.deliverySearchError);
      } else if (this.deliveryAddressResults.length <= 2) {
        this.deliverySearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Delivery Address search failed. Error:', error);
    });
  }

  setDeliveryAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowDeliveryAddressManualEntry = true;
      if (!this.shopifyOrder.shipping_address.address1 && !this.shopifyOrder.shipping_address.zip) {
        this.shopifyOrder.shipping_address.zip = this.deliverySearchPostCode;
      }
      this.orderMetadata.shippingAddressValidated = false;
      return;
    }
    this.allowDeliveryAddressManualEntry = false;
    // Don't want to copy Role
    this.shopifyOrder.shipping_address.address1 = selectedAddress.addressOne;
    this.shopifyOrder.shipping_address.address2 = selectedAddress.addressTwo;
    this.shopifyOrder.shipping_address.city = selectedAddress.city;
    this.shopifyOrder.shipping_address.province = selectedAddress.county;
    this.shopifyOrder.shipping_address.zip = selectedAddress.postcode;
    this.orderMetadata.shippingAddressValidated = selectedAddress.validated;
  }

  alarmUserAddressSearch() {
    if (!this.alarmUserSearchPostCode) {
      return;
    }
    this.alarmUserAddressResults = [];
    this.allowAlarmUserAddressManualEntry = false;
    this.alarmUserSearchError = '';
    this.getAddrClient.find(this.alarmUserSearchPostCode).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.alarmUserAddressResults = getLookupFromGetAddressResult(this.alarmUserSearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.alarmUserSearchError = addressResult.toFailed().message;
        console.error('Alarm User Address search failed. Error:', this.alarmUserSearchError);
      } else if (this.alarmUserAddressResults.length <= 2) {
        this.alarmUserSearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Alarm User Address search failed. Error:', error);
    });
  }

  setAlarmUserAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowAlarmUserAddressManualEntry = true;
      if (!this.alarmUserAddress.addressOne && !this.alarmUserAddress.postcode) {
        this.alarmUserAddress.postcode = this.alarmUserSearchPostCode;
      }
      this.alarmUserAddress.validated = false;
      return;
    }
    this.allowAlarmUserAddressManualEntry = false;
    // Don't want to copy Role
    this.alarmUserAddress = {
      'addressOne': selectedAddress.addressOne,
      'addressTwo': selectedAddress.addressTwo,
      'city': selectedAddress.city,
      'county': selectedAddress.county,
      'postcode': selectedAddress.postcode,
      'validated': selectedAddress.validated,
      'unknown': false,
    };
  }

  validateBillingAddress(fromPassedData: boolean) {
    // If fromPassedData then alarm user address set from the same data, so unlock it too if there's a problem
    this.allowBillingAddressManualEntry = false;
    if (fromPassedData) {
      this.allowAlarmUserAddressManualEntry = false;
    }
    if (this.orderMetadata.billingAddressValidated) {
      return;
    }
    const addressToValidate: Address = {
      'addressOne': this.shopifyOrder.billing_address.address1,
      'addressTwo': this.shopifyOrder.billing_address.address2,
      'city': this.shopifyOrder.billing_address.city,
      'county': this.shopifyOrder.billing_address.province,
      'postcode': this.shopifyOrder.billing_address.zip,
      'validated': this.orderMetadata.billingAddressValidated,
    };
    validateAddress(this.getAddrClient, addressToValidate).then(
      (addressValResponse: MultiRecordResponse<SelectItem<Address>>) => {
        if (addressValResponse.success) {
          this.orderMetadata.billingAddressValidated = true;
          if (fromPassedData) {
            this.alarmUserAddress.validated = true;
          }
          return;
        }
        this.billingSearchError = addressValResponse.message;
        this.billingSearchPostCode = this.shopifyOrder.billing_address? this.shopifyOrder.billing_address.zip: '';
        this.orderMetadata.billingAddressValidated = false;
        if (fromPassedData) {
          this.alarmUserSearchError = addressValResponse.message;
          this.alarmUserSearchPostCode = this.shopifyOrder.billing_address? this.shopifyOrder.billing_address.zip: '';
          this.alarmUserAddress.validated = false;
        }
        if (!addressValResponse.data) {
          this.showErrorPopUp('Error Validating Address',
            `Error validating the existing address. Please check it to make sure it is correct. Reason: ${addressValResponse.message}`
          );
        } else {
          this.billingAddressResults = addressValResponse.data;
          if (fromPassedData) {
            this.alarmUserAddressResults = addressValResponse.data;
          }
          this.showInfoPopUp('Possible Invalid Address',
            `The existing address could not be validated please check it is correct. Reason: ${addressValResponse.message}`
          );
        }
      }
    );
  }

  renewalMethodChange() {
    //TODO code in gocardless form - both in html and here
    if ('directDebit' == this.orderMetadata.renewalMethod) {
      // this.directDebit.addressLineOne=this.infoBilling.userAddress.addressOne;
      // this.directDebit.addressLineTwo=this.infoBilling.userAddress.addressTwo;
      // this.directDebit.city=this.infoBilling.userAddress.city;
      // this.directDebit.postcode = this.infoBilling.userAddress.postcode;
      this.showInfoPopUp('Reminder', 'Remember to tell the customer about direct debit guarantee.');
    // } else {
    //   this.directDebit.addressLineOne='';
    //   this.directDebit.addressLineTwo='';
    //   this.directDebit.city='';
    //   this.directDebit.postcode='';
    }
  }

  changeHowHeard(): void {
    if (this.baseOrderInformation.cseOrder.howHeard != 'Partnership') {
      this.selectedPartnership = undefined;
    }
  }

  changePartnership(): void {
    if (this.discountCode || !this.selectedPartnership) {
      return;
    }
    const partnerDiscountCode: string = this.selectedPartnership.bgcCouponCode;
    if (partnerDiscountCode) {
      this.confirmationService.confirm({
        'key': 'general',
        'header': 'Partnership Coupon',
        'message': 
          `${this.selectedPartnership.bgcName} has a coupon code ${partnerDiscountCode} configured. Would you like to apply it now?`,
        'rejectVisible': true,
        'acceptLabel': 'Yes',
        'rejectLabel': 'No',
        'icon': 'pi pi-question-circle',
        'accept': () => {
          this.discountCode = partnerDiscountCode;
          this.searchDiscount();
        },
        'reject': () => {}
      });
    }
  }

  manuallyCompleteStep(event: Event) {
    this.processingSteps[this.currentStep].completed = (event.target as HTMLInputElement).checked;
  }

  get isPlaceOrderDisabled(): boolean {
    const tmpItems: ShopifyBasketItem[] = this.shopifyItems.filter((shopifyItem: ShopifyBasketItem) => 
      shopifyItem.quantity && (shopifyItem.quantity > 0)
    );
    if (tmpItems.length == 0) {
      return true;
    }
    if (!this.orderMetadata.paymentMethod || (this.orderMetadata.paymentMethod.trim() == '')) {
      return true;
    }
    if ((this.orderMetadata.paymentMethod == 'Secured Debit/Credit Card') &&
        (!this.orderMetadata.nameOnCard || (this.orderMetadata.nameOnCard.trim() == ''))) {
      return true;
    }
    if (isNaN(this.orderTotal) || (this.orderTotal < 0)) {
      return true;
    }
    if (this.overrideTotal) {
      if (!this.baseOrderInformation.cseOrder.overrideReason ||
          ((this.baseOrderInformation.cseOrder.overrideReason == 'Other') && !this.baseOrderInformation.cseOrder.overrideReasonOther)) {
        return true;
      }
    }
    return false;
  }

  createCardPaidOrder() {
    this.showPlaceOrder = false;
    // Make sure payment steps are set to required
    this.processingSteps['Creating Payment Method on Stripe'].required = true;
    this.processingSteps['Creating Payment Intent on Stripe'].required = true;
    // Set Whether the Direct Debit Form is required
    this.processingSteps['Creating Direct Debit Form'].required = (this.orderMetadata.renewalMethod == 'directDebit');
    const stepName: string = 'Validating Order';
    if (!this.processingSteps[stepName].completed) {
      this.currentStep = stepName;
      if (this.validationErrorsExist()) {
        this.currentStep = '';
        this.showPlaceOrder = true;
        return;
      }
      this.processingSteps[this.currentStep].completed = true;
    }
    this.createPaymentMethod();
  }

  createOrder() {
    this.showPlaceOrder = false;
    // Make sure payment steps are set to not required
    this.processingSteps['Creating Payment Method on Stripe'].required = false;
    this.processingSteps['Creating Payment Intent on Stripe'].required = false;
    // Set Whether the Direct Debit Form is required
    this.processingSteps['Creating Direct Debit Form'].required = (this.orderMetadata.renewalMethod == 'directDebit');
    const stepName: string = 'Validating Order';
    if (!this.processingSteps[stepName].completed) {
      this.currentStep = stepName;
      if (this.validationErrorsExist()) {
        this.currentStep = '';
        this.showPlaceOrder = true;
        return;
      }
      this.processingSteps[stepName].completed = true;
    }
    this.captureOrderDetailsForReporting();
  }

  validationErrorsExist(): boolean {
    this.validationErrors = [];
    this.changeDetector.detectChanges();
    try {
      if (this.shopifyItems.length === 0) {
        this.validationErrors.push('No Product was added to the basket');
      } else {
        const hasZeroQtyItems: boolean = this.shopifyItems.some((item: ShopifyBasketItem) => 
          !item.quantity || (item.quantity <= 0)
        );
        if (hasZeroQtyItems) {
          this.validationErrors.push('Products must have a quantity of 1 or more. Please remove any items not required.');
        }
      }
      if (this.customerFirstName.trim() === '' || this.customerLastName.trim() === '' || this.customerPhone.trim() === '' ||
          this.shopifyOrder.billing_address.address1.trim() === '' || this.shopifyOrder.billing_address.city.trim() === '' ||
          this.shopifyOrder.billing_address.zip.trim() === '') {
        this.validationErrors.push('Missing required Billing fields');
      }
      // if ((this.infoBilling.email == null) || (this.infoBilling.email == undefined) || (this.infoBilling.email.trim() == '')) {
      //   this.woodata.generatedEmail = true;
      //   let emailStart: string = this.infoBilling.firstName+'.'+this.infoBilling.lastName+'.'+ Math.floor(Math.random() * 10000);
      //   emailStart = emailStart.replace(/[^A-Za-z0-9\.]/g, '_').replace(/\.\.+/g, '.');
      //   this.infoBilling.email = `${emailStart}@${this.emailSuffix}`;
      // }
      this.shopifyCustomer.email = this.shopifyCustomer.email.trim();
      if (this.shopifyCustomer.email && !isValidEmailAddress(this.shopifyCustomer.email)) {
        this.validationErrors.push('Billing Email is invalid');
      }
      this.validateDifferentDelivery();
      if (!this.selectedOrderType) {
        this.validationErrors.push('Missing required Order Type');
      }
      if ((this.selectedOrderType.title == 'Phone Order') && !this.baseOrderInformation.withheldNumber &&
          ((this.baseOrderInformation.cseOrder.calledFrom == '') || !isValidAnyCountryPhoneNumber(this.baseOrderInformation.cseOrder.calledFrom))) {
        this.validationErrors.push('Missing or Invalid required Customer Called From'); 
      }
      if (!this.orderMetadata.paymentMethod || (this.orderMetadata.paymentMethod.trim() === '')) {
        this.validationErrors.push('Missing required Payment Method');
      }
      if (this.isExistingCustomer()) {
        if (!this.order && !this.baseOrderInformation.allowReplacementWithoutTd) {
          this.validationErrors.push(`You must enter an existing order's TD code or tick "No existing CRM Account" for ${this.baseOrderInformation.orderType.title} orders`);
        }
        if (this.lifetimeLineNeedsOverride) {
          this.validationErrors.push('You must override the price for all rental items on Lifetime orders as the CRM cannot calculate the values');
        }
        if (this.hasRentalItems && this.baseOrderInformation.allowReplacementWithoutTd) {
          this.validationErrors.push(`You cannot add rental items unless you have provided a TD Code for ${this.baseOrderInformation.orderType.title} orders`);
        }
        if (this.isReplacementDueToFault() && (!this.baseOrderInformation.cseOrder.fault ||
            ((this.baseOrderInformation.cseOrder.fault == 'Other') && !this.baseOrderInformation.cseOrder.otherFault))) {
          this.validationErrors.push('You must enter details of the fault.');
        }
      } else {
        if (!this.isDiscountValid()) {
          this.validationErrors.push('Coupon is invalid - please remove it');
        }
        this.validateAlarmUserDetails();
        if (!this.orderMetadata.renewalMethod || (this.orderMetadata.renewalMethod.trim() === '')) {
          this.validationErrors.push('Missing required Renewal Method field');
        }
        if (!this.orderMetadata.ncfRequired || (this.orderMetadata.ncfRequired.trim() === '')) {
          this.validationErrors.push('Missing required Paper NCF field');
        }
      }
      if (this.calculationError) {
        this.validationErrors.push(`Error calculating basket value: ${this.calculationError}`);
      }
      if (this.validationErrors.length > 0) {
        console.error('Validation errors found', this.validationErrors);
        return true;
      }
    } catch (error: any) {
      this.validationErrors.push(`Error validating order. Error: ${error.message? error.message: 'unknown error'}`);
      return true;
    }
    try {
      this.prepareShopifyData();
    } catch (error: any) {
      this.validationErrors.push(`Error preparing Shopify data. Error: ${error.message? error.message: 'unknown error'}`);
      return true;
    }
    return false;

  }

  isDiscountValid(): boolean {
    this.discountCodeError = '';
    if (!this.discountCode) {
      return true;
    }
    if (this.discountCode && (!this.discount || !this.discount.code)) {
      this.discountCodeError = 'Discount code not found';
    }
    if (!this.discount || !this.discount.code) {
      return false;
    }
    if (!['ORDER', 'PRODUCT'].includes(this.discount.discountType)) {
      this.discountCodeError = 'Invalid discount type';
      return false;
    }
    if (!this.isDiscountDateValid()) {
      return false;
    }
    if (!this.isOrderValueValidForDiscount()) {
      return false;
    }
    if (!this.areProductsValidForDiscount()) {
      return false;
    }
    return true;
  }

  isDiscountDateValid(): boolean {
    if (this.discount) {
      if (this.discount.expiryDate && this.baseOrderInformation.currentMoment.isAfter(this.discount.expiryDate)) {
        this.discountCodeError = 'Discount has expired';
        return false;
      }
      if (this.discount.startDate && this.baseOrderInformation.currentMoment.isBefore(this.discount.startDate)) {
        this.discountCodeError = 'Discount is not yet active';
        return false;
      }
    }
    return true;
  }
  
  isOrderValueValidForDiscount(): boolean {
    if (!this.discount) {
      return true;
    }
    const minAllowed: number = Number(this.discount.minimumOrderValue);
    if (!!minAllowed && !isNaN(minAllowed) && (minAllowed > 0) && (minAllowed > this.orderTotal)) {
      this.discountCodeError = 'Order does not meet the minimum value for the discount';
      return false;
    }
    return true;
  }

  areProductsValidForDiscount(): boolean {
    if (this.shopifyItems.length == 0) {
      return false;
    }
    const setupFeeConfig: SetupFeeConfig = SHOPIFY_SETUP_FEE_CONFIGS[this.selectedWebsite._id];
    if ((this.discount.productIds.length == 0) && (!setupFeeConfig || (setupFeeConfig.productsRequiringReducedFee.length == 0))) {
      // No product restrictions
      return true;
    }
    let containsAllowedItems: boolean = false;
    let containsNoDiscountItems: boolean = false;
    for (let item of this.shopifyItems) {
      // Explicitly allowed item
      if (this.discount.productIds.includes(item.productId) || this.discount.variantProductIds.includes(item.shopifyVariant.shopifyVariantId)) {
        containsAllowedItems = true;
      }
      if (this.discount.collectionIds.some((collectionId: string) => item.shopifyProduct.shopifyCollectionIds.includes(collectionId))) {
        containsAllowedItems = true;
      }
      if (setupFeeConfig && (setupFeeConfig.productsRequiringReducedFee.includes(item.productId))) {
        containsNoDiscountItems = true;
      }
    }
    if (containsNoDiscountItems) {
      this.discountCodeError = 'No coupons can be used on orders wtih reduced setup fee items';
      return false;
    }
    // Regardless of whether it is a order or product coupon it needs at least one allowed item
    if ((this.discount.discountType == 'PRODUCT') && !containsAllowedItems) {
      this.discountCodeError = 'Order does not contain required items for coupon';
      return false;
    }
    return true;
  }

  validateDifferentDelivery() {
    if (!this.differentDeliver) {
      return;
    }
    if (this.shopifyOrder.shipping_address.first_name.trim() === '' || this.shopifyOrder.shipping_address.last_name.trim() === '' ||
        this.shopifyOrder.shipping_address.address1.trim() === '' || this.shopifyOrder.shipping_address.city.trim() === '' ||
        this.shopifyOrder.shipping_address.zip.trim() === '') {
      this.validationErrors.push('Missing required Delivery fields');
    }
  }

  validateAlarmUserDetails() {
    if (!this.alarmUserNameOption) {
      this.validationErrors.push("You must select an option for the Alarm User's name");
    } else if ((this.alarmUserNameOption == 'other') &&
        (!this.orderMetadata.alarmUserLastName || (this.orderMetadata.alarmUserLastName.trim() === '') ||
        !this.orderMetadata.alarmUserFirstName || (this.orderMetadata.alarmUserFirstName.trim() === ''))) {
      this.validationErrors.push("Missing required Alarm User's Name fields");
    }
    if (!this.alarmUserPhoneOption) {
      this.validationErrors.push("You must select an option for the Alarm User's phone number");
    } else if ((this.alarmUserPhoneOption == 'other') &&
        (!this.orderMetadata.alarmUserPhone || (this.orderMetadata.alarmUserPhone.trim() === '')) && 
        (!this.orderMetadata.alarmUserMobile || (this.orderMetadata.alarmUserMobile.trim() === ''))) {
      this.validationErrors.push("Missing required Alarm User's Phone number or Mobile");
    }
    if (!this.alarmUserAddressOption) {
      this.validationErrors.push("You must select an option for the Alarm User's address");
    } else if ((this.alarmUserAddressOption == 'other') &&
        ((!this.alarmUserAddress.addressOne || this.alarmUserAddress.addressOne.trim() === '') || (!this.alarmUserAddress.city || this.alarmUserAddress.city.trim() === '') ||
        (!this.alarmUserAddress.postcode || this.alarmUserAddress.postcode.trim() === ''))) {
      this.validationErrors.push("Missing required Alarm User's Address fields");
    }
  }

  prepareShopifyData() {
    this.shopifyOrder.note_attributes.push({
      'name': 'order_type',
      'value': this.selectedOrderType.title,
    });

    this.shopifyOrder.note_attributes.push({
      'name': 'order_taker',
      'value': this.userName,
    });

    this.shopifyOrder.note_attributes.push({
      'name': 'email_marketing',
      'value': (this.shopifyCustomer.emailMarketingConsent.marketingState == CustomerEmailMarketingState.SUBSCRIBED)? 'Opted In': 'Opted Out',
    });

    this.shopifyOrder.note_attributes.push({
      'name': 'phone_marketing',
      'value': (this.shopifyCustomer.smsMarketingConsent.marketingState == CustomerSmsMarketingState.SUBSCRIBED)? 'Opted In': 'Opted Out',
    });

    if (['Cheque Payment'].includes(this.orderMetadata.paymentMethod)) {
      this.shopifyOrder.financial_status = 'paid';
      this.shopifyOrder.transactions.push({
        'kind': 'sale',
        'status': 'success',
        'amount': this.orderTotal,
      });
    }

    this.shopifyOrder.total_tax = this.vatTotal;

    if (this.hasRentalItems) {
      if (this.orderRenewalPeriod && !['', 'none'].includes(this.orderRenewalPeriod)) {
        this.shopifyOrder.note_attributes.push({
          'name': 'subscription_period',
          'value': this.orderRenewalPeriod,
        });
      }
    }

    if (!this.differentDeliver) {
      this.shopifyOrder.shipping_address = this.shopifyOrder.billing_address;
      this.orderMetadata.shippingAddressValidated = this.orderMetadata.billingAddressValidated;
    }

    if (!this.isExistingCustomer()) {
      if (this.alarmUserNameOption == 'billing') {
        this.orderMetadata.alarmUserFirstName = this.shopifyOrder.billing_address.first_name;
        this.orderMetadata.alarmUserLastName = this.shopifyOrder.billing_address.last_name;
      } else  if (this.alarmUserNameOption == 'delivery') {
        this.orderMetadata.alarmUserFirstName = this.shopifyOrder.shipping_address.first_name;
        this.orderMetadata.alarmUserLastName = this.shopifyOrder.shipping_address.last_name;
      }
      if (this.alarmUserPhoneOption == 'billing') {
        this.orderMetadata.alarmUserPhone = this.shopifyCustomer.phone;
        // We only capture one billing phone, so put it in the correct field
        if (this.orderMetadata.alarmUserPhone.startsWith('+447')) {
          this.shopifyOrder.note_attributes.push({
            'name': 'alarm_user_mobile',
            'value': this.orderMetadata.alarmUserPhone,
          });
        } else {
          this.shopifyOrder.note_attributes.push({
            'name': 'alarm_user_phone',
            'value': this.orderMetadata.alarmUserPhone,
          });
        }
      } else {
        this.shopifyOrder.note_attributes.push({
          'name': 'alarm_user_phone',
          'value': this.orderMetadata.alarmUserPhone,
        });
        this.shopifyOrder.note_attributes.push({
          'name': 'alarm_user_mobile',
          'value': this.orderMetadata.alarmUserMobile,
        });
      }
      this.shopifyOrder.note_attributes.push({
        'name': 'alarm_user_first_name',
        'value': this.orderMetadata.alarmUserFirstName,
      });
      this.shopifyOrder.note_attributes.push({
        'name': 'alarm_user_last_name',
        'value': this.orderMetadata.alarmUserLastName,
      });

      this.shopifyOrder.note_attributes.push({
        'name': 'alarm_user_address_option',
        'value': this.alarmUserAddressOption,
      });

      if (this.alarmUserAddressOption == 'other') {
        this.shopifyOrder.note_attributes.push({
          'name': 'alarm_user_address',
          'value': JSON.stringify(this.alarmUserAddress),
        });
      }
    }

    if (this.shopifyCustomer.email) {
      this.shopifyOrder.send_receipt = true;
      this.shopifyCustomer.send_email_invite = true;
    }

    this.shopifyCustomer.addresses.push(this.shopifyOrder.billing_address);
    this.shopifyCustomer.addresses.push(this.shopifyOrder.shipping_address);
    this.shopifyOrder.note_attributes.push({
      'name': 'billing_address_validated',
      'value': this.orderMetadata.billingAddressValidated? 'Yes': 'No',
    });
    this.shopifyOrder.note_attributes.push({
      'name': 'shipping_address_validated',
      'value': this.orderMetadata.shippingAddressValidated? 'Yes': 'No',
    });
       
    if (this.order) {
      this.shopifyOrder.note += `Existing Order TD Code: ${this.order.alarmUserDetails.tdCode}\nCompany: ${this.order.legalCompany}`;
      this.shopifyOrder.note_attributes.push({
        'name': 'existing_order_td_code',
        'value': this.order.alarmUserDetails.tdCode,
      });
    }
    
    if (this.isExistingCustomer()) {
      if (this.isAdditional()) {
        this.shopifyOrder.note += 'Additional Equipment, not replacement\n';
      } else {
        this.shopifyOrder.note += `Reason for Replacement: ${this.selectedOrderType.title}\n`;
      }
    }
    if (this.isReplacementDueToFault()) {
      this.shopifyOrder.note += `Fault: ${this.baseOrderInformation.cseOrder.fault} ${this.baseOrderInformation.cseOrder.otherFault}\n`;
    }

    // TODO set metadata on order from
    // this.orderMetadata.ncfRequired

    if (this.discount && this.discount.code) {
      this.shopifyOrder.discount_codes = [{
        'code': this.discount.code,
        'amount': this.discount.discount.toFixed(2),
        'type': this.discount.valueType,
      }];
    }
    
    this.shopifyItems.forEach((item: ShopifyBasketItem, itemIdx: number) => {
      let originalPrice: number = item.originalPrice * item.quantity;
      let finalPrice: number = item.regularPrice * item.quantity;
      let finalVat: number = 0.00;
      if (item.shopifyVariant.taxable) {
        originalPrice = originalPrice * EX_TO_INC_VAT_MULTIPLIER;
        finalVat = finalPrice * VAT_MULTIPLIER;
        finalPrice = finalPrice + finalVat;
      }

      if (!item.shopifyProduct.isBundle) {
        let shortProductId: string = item.shopifyProduct.shopifyProductId;
        if (/^gid:\/\/shopify\/Product\//.test(shortProductId)) {
          shortProductId = shortProductId.replace('gid://shopify/Product/', '');
        }
        let shortVariantId: string = item.shopifyVariant.shopifyVariantId;
        if (/^gid:\/\/shopify\/ProductVariant\//.test(shortVariantId)) {
          shortVariantId = shortVariantId.replace('gid://shopify/ProductVariant/', '');
        }
        const shopifyOrderLine: ShopifyRestOrderLine = {
          'product_id': shortProductId,
          'variant_id': shortVariantId,
          'quantity': item.quantity,
          'price': item.subtotalExVat,
          'tax_lines': [],
        };
        if (item.shopifyVariant.taxable) {
          shopifyOrderLine.tax_lines.push({
            'channel_liable': false,
            'rate': VAT_MULTIPLIER,
            'title': 'GB VAT',
            'price': item.subtotalExVat * VAT_MULTIPLIER,
          });
        }
        this.shopifyOrder.line_items.push(shopifyOrderLine);
        this.baseOrderInformation.cseOrder.cseOrderItems.push({
          'cseOrderLine': itemIdx + 1,
          'cseOrderLinePart': 1,
          'cseOrderTitle': item.shopifyVariant.crmVariantTitle,
          'originalPrice': originalPrice,
          'finalPrice': finalPrice,
          'finalVat': finalVat,
          'quantity': item.quantity,
          'shopifyProductId': item.shopifyProduct.shopifyProductId,
          'shopifyVariantId': item.shopifyVariant.shopifyVariantId,
          'hardwareId': item.shopifyVariant.hardware? item.shopifyVariant.hardware._id: null,
        });
      } else {
        // TODO this will need changing for existing customer order types
        // Need to get the total so we can allocate price proportionally
        let totalSetupFeeAndSubPrice: number = 0.00;
        let partId: number = 1;
        item.shopifyVariant.productVariantComponents.forEach((svc: ShopifyVariantComponent) => {
          let shortProductId: string = svc.productVariant.product.id;
          if (/^gid:\/\/shopify\/Product\//.test(shortProductId)) {
            shortProductId = shortProductId.replace('gid://shopify/Product/', '');
          }
          let shortVariantId: string = svc.productVariant.id;
          if (/^gid:\/\/shopify\/ProductVariant\//.test(shortVariantId)) {
            shortVariantId = shortVariantId.replace('gid://shopify/ProductVariant/', '');
          }
          const product: RawShopifyProduct = this.productMap[svc.productVariant.product.id];
          const variant: PopulatedShopifyVariant = this.variantMap[svc.productVariant.id];
          const isSetupFeeOrSub: boolean = product.shopifyCollectionNames.some((collectionName: string) => 
            ['Setup Fees', 'Subscriptions'].includes(collectionName)
          );
          if (isSetupFeeOrSub) {
            totalSetupFeeAndSubPrice += variant.regularPrice;
          }
        });
        // Need to loop through again now we know the total for the proportioning
        item.shopifyVariant.productVariantComponents.forEach((svc: ShopifyVariantComponent) => {
          let shortProductId: string = svc.productVariant.product.id;
          if (/^gid:\/\/shopify\/Product\//.test(shortProductId)) {
            shortProductId = shortProductId.replace('gid://shopify/Product/', '');
          }
          let shortVariantId: string = svc.productVariant.id;
          if (/^gid:\/\/shopify\/ProductVariant\//.test(shortVariantId)) {
            shortVariantId = shortVariantId.replace('gid://shopify/ProductVariant/', '');
          }
          const product: RawShopifyProduct = this.productMap[svc.productVariant.product.id];
          const variant: PopulatedShopifyVariant = this.variantMap[svc.productVariant.id];
          const isSetupFeeOrSub: boolean = product.shopifyCollectionNames.some((collectionName: string) => 
            ['Setup Fees', 'Subscriptions'].includes(collectionName)
          );
          const priceMultiplier: number = variant.regularPrice / totalSetupFeeAndSubPrice;
          let unitPrice: number = 0.00;
          if (isSetupFeeOrSub) {
            // Divide by svc quantity as it will multiple up again as this is a unit price
            unitPrice = (item.subtotalExVat * priceMultiplier) / svc.quantity;
          }
          const shopifyOrderLine: ShopifyRestOrderLine = {
            'product_id': shortProductId,
            'variant_id': shortVariantId,
            'quantity': item.quantity * svc.quantity,
            'price': unitPrice,
            'tax_lines': [],
          };
          if (isSetupFeeOrSub && item.shopifyVariant.taxable) {
            shopifyOrderLine.tax_lines.push({
              'channel_liable': false,
              'rate': VAT_MULTIPLIER,
              'title': 'GB VAT',
              'price': unitPrice * VAT_MULTIPLIER,
            });
          }
          this.baseOrderInformation.cseOrder.cseOrderItems.push({
            'cseOrderLine': itemIdx + 1,
            'cseOrderLinePart': partId,
            'cseOrderTitle': variant.crmVariantTitle,
            'originalPrice': isSetupFeeOrSub? item.originalPrice * priceMultiplier: 0.00,
            'finalPrice': isSetupFeeOrSub? finalPrice * priceMultiplier: 0.00,
            'finalVat': isSetupFeeOrSub? finalVat * priceMultiplier: 0.00,
            'quantity': item.quantity * svc.quantity,
            'shopifyProductId': product.shopifyProductId,
            'shopifyVariantId': variant.shopifyVariantId,
            'hardwareId': item.shopifyVariant.hardware? item.shopifyVariant.hardware._id: null,
          });
          this.shopifyOrder.line_items.push(shopifyOrderLine);
        });
      }
    });

    console.log('Data to be sent to website', this.shopifyOrder);
  }

  async createPaymentMethod() {
    this.showPlaceOrder = false;
    this.showProgressBar = true;
    this.processingError = null;
    /*
      Even if 'Creating Payment Method on Stripe' completed we need to redo it to get the paymentMethod id for 
      'Creating Payment Intent on Stripe' if that step has not been completed
    */
    if (this.processingSteps['Creating Payment Intent on Stripe'].completed) {
      this.captureOrderDetailsForReporting();
    } else {
      this.currentStep = 'Creating Payment Method on Stripe';
      const paymentMethodResult: PaymentMethodResult = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
      });
      try {
        if (paymentMethodResult.error) {
          this.processingError = paymentMethodResult.error;
          this.showProgressBar = false;
        } else {
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = paymentMethodResult.paymentMethod.id;
          this.shopifyOrder.note_attributes.push({
            'name': 'charge.payment_method_details.type',
            'value': paymentMethodResult.paymentMethod.id,
          });
          this.createPaymentIntent();
        }
      } catch(error: any) {
        this.processingError = {'message': error.message};
        console.error('Payment processing error', this.processingError);
        this.showProgressBar = false;
      };
    }
  }

  createPaymentIntent() {
    const custName: string = `${this.shopifyCustomer.first_name} ${this.shopifyCustomer.last_name}`;
    let paymentDescription: string = '';
    let customerDescription: string = custName;
    const metadata: StringIndexedObject<string> = {};
    if (this.order) {
      paymentDescription = `${this.order.alarmUserDetails.tdCode} existing customer order`;
      customerDescription = `${this.order.alarmUserDetails.tdCode} ${custName}`;
      metadata.tdCode = this.order.alarmUserDetails.tdCode;
    } else {
      paymentDescription = `${custName} - CSE Order`;
    }
    const createPaymentIntentRequest: CreatePaymentIntentRequest = {
      'stripeBrand': this.stripeBrand,
      'amount': this.orderTotal.toFixed(2),
      'currency': this.shopifyOrder.currency.toLocaleLowerCase(),
      'description': paymentDescription,
      'customer_name': this.orderMetadata.nameOnCard,
      'paymentMethod': this.processingSteps['Creating Payment Method on Stripe'].resultData,
      'billing_details': {
        'address': {
          'line1': this.shopifyOrder.billing_address.address1,
          'line2': this.shopifyOrder.billing_address.address2,
          'city': this.shopifyOrder.billing_address.city,
          'postal_code': this.shopifyOrder.billing_address.zip,
        },
        'name': custName,
        'phone': this.shopifyCustomer.phone,
        'description': customerDescription,
        'metadata': metadata,
      },
    };
    if (this.shopifyCustomer.email) {
      createPaymentIntentRequest.billing_details.email = this.shopifyCustomer.email;
    }
    if (this.order && this.order.accountDetails.stripeCustomerId && this.order.accountDetails.stripeCustomerId.startsWith('cus_')) {
      createPaymentIntentRequest.customer_id = this.order.accountDetails.stripeCustomerId;
    }
    this.currentStep = 'Creating Payment Intent on Stripe';
    this.orderService.createOrderPaymentIntent(createPaymentIntentRequest).subscribe({
      next: (rsp: SingleRecordResponse<ServerSideStripe.PaymentIntent>) => {
        console.log(rsp);
        if (!rsp.success) {
          const error: StripeError & {raw: any} = rsp.error;
          this.processingError = {'message': rsp.message, 'code': error.code};
          // error.type is StripeCardError typing says it should be card_error, which is what the type on the raw has
          this.processingSteps[this.currentStep].allowToBeSkipped = (error.raw && (error.raw.type != 'card_error'));
          this.showProgressBar = false;
          return;
        } else if (rsp.data.status != 'succeeded') {
          this.processingError = {'message': 'Payment Intent created, but not at "succeeded" status'};
          this.processingSteps[this.currentStep].allowToBeSkipped = false;
          this.showProgressBar = false;
        } else {
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = rsp.data.id;
          this.shopifyOrder.note_attributes.push({
            'name': 'stripe.customer_id',
            'value': (rsp.data.customer as string),
          });
          this.shopifyOrder.note_attributes.push({
            'name': 'stripe.payment_id',
            'value': rsp.data.id,
          });
          this.shopifyOrder.financial_status = 'paid';
          this.shopifyOrder.transactions.push({
            'kind': 'sale',
            'status': 'success',
            'amount': this.orderTotal,
          });
          if (rsp.data.latest_charge && (rsp.data.latest_charge as ServerSideStripe.Charge).balance_transaction) {
            const balanceTransaction: ServerSideStripe.BalanceTransaction =
              ((rsp.data.latest_charge as ServerSideStripe.Charge).balance_transaction as ServerSideStripe.BalanceTransaction);
            if (balanceTransaction.fee) {
              this.shopifyOrder.note_attributes.push({
                'name': 'stripe.processing_fee',
                'value': roundToTwoDecimalPlaces(balanceTransaction.fee/100.00).toFixed(2),
              });
            }            
          }
          this.captureOrderDetailsForReporting();
        }
      },
      error: (err: any) => {
        console.error('Error creating payment intent on stripe', err);
        this.processingError = {'message': err.message};
        console.error('Something is wrong: V2', this.processingError);
        this.showProgressBar = false;
      }
    });
  }

  captureOrderDetailsForReporting() {
    this.showProgressBar = true;
    this.showPlaceOrder = false;
    this.processingError = null;
    const stepName: string = 'Capturing Details for Reporting';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      this.captureDdInformation();
    } else {
      this.currentStep = stepName;
      this.baseOrderInformation.cseOrder.orderType = this.selectedOrderType.title;
      this.baseOrderInformation.cseOrder.originalOrderTotal = this.totalBeforeDiscountAndOverride;
      this.baseOrderInformation.cseOrder.finalOrderTotal = this.orderTotal;
      this.baseOrderInformation.cseOrder.finalOrderVat = this.vatTotal;
      this.baseOrderInformation.cseOrder.couponCode = (this.discount)? this.discount.code: '';
      this.baseOrderInformation.cseOrder.couponAmount = this.discountAmount;
      this.baseOrderInformation.cseOrder.partnership = this.selectedPartnership? this.selectedPartnership.bgcName: '',
      this.baseOrderInformation.cseOrder.billingPostcode = this.shopifyOrder.billing_address.zip;
      this.baseOrderInformation.cseOrder.leadId =
        this.baseOrderInformation.cseOrderNavigationData? this.baseOrderInformation.cseOrderNavigationData.leadId: null;
      this.baseOrderInformation.cseOrder.paymentMethod = this.orderMetadata.paymentMethod;
      if (!this.baseOrderInformation.proRataMultiplier || (this.baseOrderInformation.proRataMultiplier <= 0)) {
        if (!this.baseOrderInformation.cseOrder.proRataCurrentPrice || (this.baseOrderInformation.cseOrder.proRataCurrentPrice <= 0) ||
            (this.selectedOrderType.calculationMethod != CalculationMethod.DIFFERENCE)) {
              this.baseOrderInformation.cseOrder.proRataCurrentPrice = 0;
        }
        this.baseOrderInformation.cseOrder.proRataMultiplier = '';
        this.baseOrderInformation.cseOrder.proRataToDate = '';
      } else {
        this.baseOrderInformation.cseOrder.proRataMultiplier = this.baseOrderInformation.proRataMultiplier.toFixed(6);
      }
      if (this.alarmUserAddressOption == 'other') {
        this.baseOrderInformation.cseOrder.alarmUserPostcode = this.alarmUserAddress.postcode;
      } else if (this.alarmUserAddressOption == 'delivery') {
        this.baseOrderInformation.cseOrder.alarmUserPostcode = this.shopifyOrder.shipping_address.zip;
      } else if (this.alarmUserAddressOption == 'billing') {
        this.baseOrderInformation.cseOrder.alarmUserPostcode = this.shopifyOrder.billing_address.zip;
      }
      this.orderService.createRecordOfCseOrder({cseOrder: this.baseOrderInformation.cseOrder}).subscribe({
        next: (cseOrderResponse: SingleRecordResponse<CseOrder>) => {
          if (!cseOrderResponse.success) {
            console.error('Error recording CSE order. Error:', cseOrderResponse.message);
            this.processingError = cseOrderResponse.error? cseOrderResponse.error: {'message': cseOrderResponse.message};
            this.showProgressBar = false;
          } else {
            this.processingSteps[stepName].resultData = cseOrderResponse.data._id;
            this.processingSteps[stepName].completed = true;
            this.captureDdInformation();
          }
        },
        error: (err: any) => {
          console.error('Error recording CSE order. Error:', err);
          this.processingError = err;
          this.showProgressBar = false;
        }
      });
    }
  }

  captureDdInformation() {
    const stepName: string = 'Creating Direct Debit Form';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      this.findOrCreateShopifyCustomer();
    } else {
      // TODO work out what we need to do for Shopify/GoCardless DD form
      this.currentStep = stepName;
      this.processingSteps[this.currentStep].completed = true;
      this.findOrCreateShopifyCustomer();
    }
  }

  findOrCreateShopifyCustomer() {
    const stepName: string = 'Finding or Creating Customer on Alarm Website';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      this.createOrderOnShopify();
    } else {
      this.currentStep = stepName;
      if (this.order) {
        this.orderService.getExternalIdForOrderAndExactName(this.order._id, 'Shopify Customer').subscribe({
          next: (customerIdResponse: SingleRecordResponse<ExternalId>) => {
            if (!customerIdResponse.success) {
              if (customerIdResponse.message == 'External Id not found for order') {
                // No id saved, so do a search for the customer
                this.findShopifyCustomer();
              } else {
                console.error('Error getting external id for customer. Error:', customerIdResponse.message);
                this.processingError = customerIdResponse.error? customerIdResponse.error: {'message': customerIdResponse.message};
                this.showProgressBar = false;
              }
            } else {
              this.processingSteps[this.currentStep].completed = true;
              this.processingSteps[this.currentStep].resultData = customerIdResponse.data.idValue;
              this.createOrderOnShopify();
            }
          },
          error: (error: any) => {
            console.error('Error searching for external id for customer. Error:', error);
            this.processingError = error;
            this.showProgressBar = false;
          }
        });
      } else {
        // No external id, but try to find the customer in case they already exist from another order
        this.findShopifyCustomer();
      }
    }
  }

  createExternalIdForShopifyCustomer(shopifyCustId: string, matchedData: string) {
    // Partial as has no id nor created/updated dates
    const externalId: Partial<ExternalId> = {
      'orderId': this.order._id,
      'idName': 'Shopify Customer',
      'idValue': shopifyCustId,
      'dataUsedToMatchInternal': '',
      'dataUsedToMatchExternal': '',
      'tryCount': 0,
    };
    this.orderService.createExternalId(externalId).subscribe({
      next: (externalIdResponse: SingleRecordResponse<ExternalId>) => {
        if (!externalIdResponse.success) {
          console.error('Error saving external id for customer. Error:', externalIdResponse.message);
          this.processingError = externalIdResponse.error? externalIdResponse.error: {'message': externalIdResponse.message};
          this.showProgressBar = false;
        } else {
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = shopifyCustId;
          this.createOrderOnShopify();
        }
      },
      error: (error: any) => {
        console.error('Error saving external id for customer. Error:', error);
        this.processingError = error;
        this.showProgressBar = false;
      }
    });
  }

  findShopifyCustomer() {
    this.orderService.findShopifyCustomer(this.selectedWebsite._id, this.shopifyCustomer.phone, this.shopifyCustomer.email).subscribe({
      next: (findCustResponse: SingleRecordResponse<string>) => {
        if (!findCustResponse.success) {
          if (findCustResponse.message == 'No match found') {
            // No customer found, so can create one
            this.createShopifyCustomer();
          } else {
            console.error('Error searching for existing Shopify customer. Error:', findCustResponse.message);
            this.processingError = findCustResponse.error? findCustResponse.error: {'message': findCustResponse.message};
            this.showProgressBar = false;
          }
        } else if (this.order) {
          // We have an order, so can save the id
          const matchedData: string = findCustResponse.message.includes('email')? this.shopifyCustomer.email: this.shopifyCustomer.phone;
          this.createExternalIdForShopifyCustomer(findCustResponse.data, matchedData);
        } else {
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = findCustResponse.data;
          this.createOrderOnShopify();
        }
      },
      error: (error: any) => {
        console.error('Error searching for existing Shopify customer. Error:', error);
        this.processingError = error;
        this.showProgressBar = false;
      }
    });
  }

  createShopifyCustomer() {
    this.orderService.createShopifyCustomer(this.selectedWebsite._id, this.shopifyCustomer).subscribe({
      next: (createCustResponse: SingleRecordResponse<string>) => {
        if (!createCustResponse.success) {
          console.error('Error creating Shopify customer. Error:', createCustResponse.message);
          this.processingError = createCustResponse.error? createCustResponse.error: {'message': createCustResponse.message};
          this.showProgressBar = false;
        } else if (this.order) {
          this.createExternalIdForShopifyCustomer(createCustResponse.data, 'created from order page');
        } else {
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = createCustResponse.data;
          this.createOrderOnShopify();
        }
      },
      error: (error: any) => {
        console.error('Error creating Shopify customer. Error:', error);
        this.processingError = error;
        this.showProgressBar = false;
      }
    });
  }
  
  createOrderOnShopify() {
    if (this.processingSteps[this.ORDER_CREATION_STEP].completed) {
      this.findOrderAndUpdateCseOrderRecord();
    } else {
      this.currentStep = this.ORDER_CREATION_STEP;
      // Populate the customer id we got back from the find/create stage
      this.shopifyOrder.customer.id = this.processingSteps['Finding or Creating Customer on Alarm Website'].resultData;
      this.orderService.createShopifyOrder(this.selectedWebsite._id, this.shopifyOrder).subscribe({
        next: (rsp: SingleRecordResponse<string>) => {
          if (!rsp.success) {
            console.error('Failed to create order on Alarm website', rsp.message);
            this.processingError = !!rsp.error? rsp.error: {'message': rsp.message};
            this.showProgressBar = false;
            return;
          }
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = rsp.data;
          const storeName: string = this.selectedWebsite.shopify.storeDomain.replace(/^([^.]+)\..*/, '$1');
          this.orderLink = `https://admin.shopify.com/store/${storeName}/orders/${rsp.data}`;
          this.findOrderAndUpdateCseOrderRecord();
        },
        error: (err: any) => {
          console.error('Error creating order on alarm website', err);
          this.processingError = err;
          this.showProgressBar = false;
        }
      });
    }
  }

  findOrderAndUpdateCseOrderRecord() {
    const stepName: string = 'Updating Reporting Record';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      if (this.order) {
        this.crmOrderLink = '/order/' + this.order['_id'];
      }
      this.showProgressBar = false;
      this.orderCreationSuccess = true;
      this.currentStep = '';
    } else {
      this.currentStep = stepName;
      const orderId: string = this.processingSteps[this.ORDER_CREATION_STEP].resultData;
      if (this.isExistingCustomer() || !this.orderRenewalPeriod) {
        this.updateCseOrderRecord(orderId);
      } else {
        // There should be a CRM record, so try to find it
        this.orderService.findOrder({'orderId': orderId, 'website': this.selectedWebsite._id, 'deleted': false}).subscribe({
          next: (findResponse: FindOrderResponse) => {
            if (!findResponse.success || (findResponse.orders.length == 0)) {
              this.processingError = {'message': 'Order not found in CRM'};
              this.showProgressBar = false;
            } else {
              this.order = findResponse.orders[0];
              if (this.baseOrderInformation.cseOrderNavigationData) {
                this.leadService.updateLead({
                  _id: this.baseOrderInformation.cseOrderNavigationData.leadId,
                  orderId: this.order._id,
                  tdCode: this.order.alarmUserDetails.tdCode,
                  status: 'Ordered Over Phone',
                }).subscribe({
                  next: (response: SimpleResponse) => {
                    if (response.success) {
                      this.updateCseOrderRecord(orderId);
                    } else {
                      this.processingError = {'message': 'Error updating lead with order id'};
                      this.showProgressBar = false;
                    }
                  },
                  error: (err: Error) => {
                    console.error('Error updating lead with order id', err);
                    this.processingError = {'message': 'Error updating lead with order id'};
                    this.showProgressBar = false;         
                  }
                });
              } else {
                this.updateCseOrderRecord(orderId);
              }
            }
          },
          error: (error: any) => {
            console.error('Error finding order in the CRM', error);
            this.processingError = error;
            this.showProgressBar = false;
          }
        });
      }
    }
  }

  updateCseOrderRecord(orderId: string) {
    this.orderService.updateCseOrderRecord({
      cseOrderUpdate: {
        '_id': this.processingSteps['Capturing Details for Reporting'].resultData,
        'websiteOrderId': orderId,
        'orderId': this.order? this.order._id: null,
        'tdCode': this.order? this.order.alarmUserDetails.tdCode: '',
        'currentPlan': this.order? this.order.accountDetails.plan: '',
      }
    }).subscribe({
      next: (cseOrderResponse: SimpleResponse) => {
        if (!cseOrderResponse.success) {
          console.error('Error updating CSE order. Error:', cseOrderResponse.message);
          this.processingError = !!cseOrderResponse.error? cseOrderResponse.error: {'message': cseOrderResponse.message};
          this.showProgressBar = false;
        } else {
          if (this.order) {
            this.crmOrderLink = '/order/' + this.order['_id'];
            if (!this.isExistingCustomer() || !this.hasRentalItems || 
                ![CalculationMethod.ADDITIONAL, CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod)) {
              this.showProgressBar = false;
              this.orderCreationSuccess = true;
              this.currentStep = '';
              if (!this.isExistingCustomer()) {
                this.router.navigate(['/order/' + this.order['_id']]);
              }
            } else {
              const tdCode: string = this.order.alarmUserDetails.tdCode;
              let equipmentSent: string[] = this.shopifyItems.map((basketItem: ShopifyBasketItem) => 
                  `${basketItem.shopifyVariant.crmVariantTitle} x ${basketItem.quantity}`
              );
              let overrideMsg: string = '';
              if (this.overrideTotal) {
                overrideMsg = `Note: the order total was overridden for reason: ${this.baseOrderInformation.cseOrder.overrideReason}` +
                  this.baseOrderInformation.cseOrder.overrideReasonOther;
              }
              const crmOrderLink: string = `${environment.protocol}${environment.IPAddress}/order/${this.order._id}`;
              const plainTextMsg: string = 
                `a ${this.baseOrderInformation.orderType.title} order has been placed for the below order, which will require a change to their renewal.\n` +
                `Customer Order (TD): ${tdCode} ${crmOrderLink}\nWooCommerce Order: ${this.orderLink}\n` +
                `The items on the order are: ${equipmentSent.join('\n')}` +
                `Their next renewal is ${this.baseOrderInformation.nextRenewalDate} and payment of ${this.orderTotal.toFixed(2)} ` +
                `has been taken to cover the increase up to ${this.baseOrderInformation.cseOrder.proRataToDate}.\n${overrideMsg}`;
              const recipients: string[] = JSON.parse(localStorage.getItem('email: Admin'));
              this.notificationService.sendEmail({
                'recipients': recipients,
                'subject': `Renewal Payment Change due to ${this.baseOrderInformation.orderType.title} order`,
                'plainTextMsg': `Hi Team,\n${plainTextMsg}\nThanks,\n${this.userName}`,
                'htmlMsg': 
                    `<p>Hi Team,</p>` +
                    `<p>a ${this.baseOrderInformation.orderType.title} order has been placed for the below order, which will require a change to their renewal.</p>` + 
                    `<p>Customer Order (TD): <a href='${crmOrderLink}' target='_blank'>${tdCode}</a><br/>` +
                    `WooCommerce Order: <a href='${this.orderLink}' target='_blank'>${this.processingSteps[this.ORDER_CREATION_STEP].resultData}</a></p>` +
                    `<p>The items on the order are: ${equipmentSent.join('<br/>')}</p>` +
                    `<p>Their next renewal is ${this.baseOrderInformation.nextRenewalDate} and payment of ${this.orderTotal.toFixed(2)} ` + 
                    `has been taken to cover the increase up to ${this.baseOrderInformation.cseOrder.proRataToDate}.</p><p>${overrideMsg}</p>` +
                    `<p>Thanks,<br/>${this.userName}</p>`,
              }).subscribe((emailResponse: MultiRecordResponse<string>) => {
                if (emailResponse.success) {
                  if (emailResponse.data.length > 0) {
                    this.processingError = {
                      'message': `There was an error emailing ${emailResponse.data.join(';')} to notify them. Please email them these details manually:\n${plainTextMsg}`
                    };
                    this.showProgressBar = false;
                  } else {
                    this.showProgressBar = false;
                    this.orderCreationSuccess = true;
                    this.processingSteps[this.currentStep].completed = true;
                    this.currentStep = '';
                  }
                } else {
                  this.processingError = {
                    'message': `There was an error emailing ${recipients.join(';')} to notify them. Please email them these details manually:\n${plainTextMsg}`
                  };
                  this.showProgressBar = false;
                }
              });
            }

          }
        }
      },
      error: (err: any) => {
        console.error('Error updating CSE order. Error:', err);
        this.processingError = err;
        this.showProgressBar = false;
      }
    });
  }

  // TODO add back in
  retryOrder() {
  //   // clear the previous error
  //   this.processingError = null;
  //   this.showProgressBar = true;
  //   this.displayErrorDetails = false;
  //   if ([this.ORDER_CREATION_STEP, 'Checking User Supplied OrderId'].includes(this.currentStep)) {
  //     if (!!this.draftOrderId) {
  //       this.checkOrderId();
  //       return;
  //     }
  //   } 
  //   if (this.processingSteps['Creating Payment Intent on Stripe'].required
  //       && !this.processingSteps['Creating Payment Intent on Stripe'].completed) {
  //     if (!!this.orderMetadata.paymentIntentRef) {
  //       this.checkPaymentReference();
  //     } else {
  //       // Card order, with payment not taken so go down that route
  //       this.createPaymentMethod();
  //     }
  //   } else {
  //     this.captureOrderDetailsForReporting();
  //   }
  }

  searchReferral() {
    this.referralTdFound = false;
    this.referralTdSearched = false;
    this.referralTDCode = this.referralTDCode.trim().toLocaleUpperCase();

    if (!this.referralTDCode) {
      return;
    }
    this.orderService.getOrderTdCode(this.referralTDCode, this.selectedWebsite._id).subscribe({
      'next': (response: OrderResponse) => {
        this.referralTdSearched = true;
        if (response.success && !!response.order) {
          this.referralTdFound = true;
          this.baseOrderInformation.cseOrder.referringAccount = this.referralTDCode;
        } else {
          console.error('Order not found');
        }
      },
      'error': (err: any) => {
        this.referralTdSearched = true;
        console.error('Error finding existing order from TD Code', err);
      }
    });
  }

  refresh(): void {
    window.location.reload();
  }

  showInfoPopUp(header: string, message: string): void {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('error', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
