import {ProcessingSteps} from '../../../models/cseOrder/processingSteps.model';

const SHOPIFY_PROCESSING_STEPS: ProcessingSteps = {
  'Validating Order': { 
    required: true, 
    completed: false,
  },
  'Creating Payment Method on Stripe': { 
    required: true,
    completed: false,
    messageToDisplay: 'Failed trying to create card record on Stripe to take payment against. Please check if Stripe is up and retry if/when it is.',
  },
  'Creating Payment Intent on Stripe': { 
    required: true,
    completed: false,
    messageToDisplay: 'Failed trying to take payment on Stripe. Please see the above Error detailing what is wrong, ' +
      'correct the card details and then click retry.',
    allowToBeSkipped: false,
    messageToDisplayIfCanSkip: 'Failed trying to take payment on Stripe. Please check on Stripe if the payment has ' +
      'been taken and if it has enter the payment reference from Stripe before retrying.',
  },
  'Capturing Details for Reporting': {
    required: true, 
    completed: false, 
    messageToDisplay: 'Failed trying to capture the order details for reporting. Please retry.'
  },
  'Creating Direct Debit Form': { 
    required: false,
    completed: false,
    messageToDisplay: 'Failed trying to create Direct Debit form on Alarm website. Please check if the "Direct Debit" ' +
      'form for this customer has been created on the Alarm website. If it has check the below box before retrying.',
  },
  'Finding or Creating Customer on Alarm Website': { 
    required: true,
    completed: false,
    messageToDisplay: 'Failed to find or create custoemr on alarm website. Please retry.',
  },
  'Creating Order on Alarm Website': { 
    required: true, 
    completed: false,
    messageToDisplay: 'Failed trying to create the order on the Alarm website. Please check if the order exists on the Alarm website. ' +
      'If it exists enter the order id below before retrying, otherwise if it does not exist just click Retry.',
  },
  'Updating Reporting Record': {
    required: true, 
    completed: false, 
    messageToDisplay: 'Failed trying to update information on the reporting record. Please retry.'
  },
  'Checking User Supplied Payment Reference': { 
    required: false,
    completed: false,
    allowToBeSkipped: true,
    messageToDisplay: 'Invalid Payment reference entered, please see the Error above for the reason and either correct the reference, ' +
      'or remove the reference to create a new payment on Stripe.' 
  },
  'Checking User Supplied OrderId': {
    required: false, 
    completed: false, 
    messageToDisplay: 'Invalid Order Id entered, please see the Error above for the reason and either correct the Order Id, or remove the ' +
      'Order Id to create a new order on the alarm Website.' 
  }
};

/**
 * Returns the processing steps for Shopify order creation
 * @return {ProcessingSteps} The processing steps
 */
function getShopifyProcessingSteps() : ProcessingSteps {
  return JSON.parse(JSON.stringify(SHOPIFY_PROCESSING_STEPS));
}

export {
  getShopifyProcessingSteps,
};
